/**
 * @description Custom con funciones adicionales al formulario con código: cont-cajadiario, 
 * @author Cristian Ciro
 * @version jdesk_1.01.0001.1
 **/
import FormJaivana from 'dashboard_jaivana_v1';
class CustomCajaMostrador extends FormJaivana.form {


    constructor(props) {
        super(props);

        this.nuevoPedidosPendientes                 = this.nuevoPedidosPendientes.bind(this);
        this.successNuevoPendientes                 = this.successNuevoPendientes.bind(this);
        this.mostrarClientes                        = this.mostrarClientes.bind(this);
        this.setButtonHabilitarPedido               = this.setButtonHabilitarPedido.bind(this);
        this.setButtonFacturarProducto              = this.setButtonFacturarProducto.bind(this);
        this.selectionNumeroPedido                  = this.selectionNumeroPedido.bind(this);
        this.selectionRegistro                      = this.selectionRegistro.bind(this);
        this.selectionRegistro2                     = this.selectionRegistro2.bind(this);
        this.seleccionCliente                       = this.seleccionCliente.bind(this);
        this.seleccionDocumento                     = this.seleccionDocumento.bind(this);
        this.seleccionSede                          = this.seleccionSede.bind(this);
        this.seleccionFecha                         = this.seleccionFecha.bind(this);
        this.seleccionTotal                         = this.seleccionTotal.bind(this);
        this.seleccionTipoPedido                    = this.seleccionTipoPedido.bind(this);
        this.setButtonDevolver                      = this.setButtonDevolver.bind(this);
        this.registroSeleccionado                   = '';
        this.codigoConsecutivo                      = 0;
        this.saldo_bodegas_productos                = '';

        this.susccessRespuestaSegundoServ           = this.susccessRespuestaSegundoServ.bind(this);
        this.confirmDevolverTodo                    = this.confirmDevolverTodo.bind(this);
        this.consultarItemsDevolver                 = this.consultarItemsDevolver.bind(this);
        this.successItemsDevolver                   = this.successItemsDevolver.bind(this);
        this.eliminarItemsPedido                    = this.eliminarItemsPedido.bind(this);
        this.successEliminarItems                   = this.successEliminarItems.bind(this);
        this.traerCodigoBodegaSaldo                 = this.traerCodigoBodegaSaldo.bind(this);
        this.succesTraerCodBodegaSaldo              = this.succesTraerCodBodegaSaldo.bind(this);
        this.habilitarPedido                        = this.habilitarPedido.bind(this);
        this.confirmHabilitarPedido                 = this.confirmHabilitarPedido.bind(this);
        this.successHabilitarPedido                 = this.successHabilitarPedido.bind(this);
        this.llenarpedido                           = this.llenarpedido.bind(this);
        this.successLlenarPedido                    = this.successLlenarPedido.bind(this);
        this.TraerDatosClienteFinal                 = this.TraerDatosClienteFinal.bind(this);
        this.successTraerDatosClienteFinal          = this.successTraerDatosClienteFinal.bind(this);
        this.mostrarMensajeGenerando                = this.mostrarMensajeGenerando.bind(this);

        this.ActualizarDatos                        = this.ActualizarDatos.bind(this);
        this.successActualizar                      = this.successActualizar.bind(this);

        this.enviarCorreo                           = this.enviarCorreo.bind(this);
        this.successEnviarCorreo                    = this.successEnviarCorreo.bind(this);
        this.generarPdfTabla                        = this.generarPdfTabla.bind(this);

        this.enviarsms                              = this.enviarsms.bind(this);
        this.successEnviarSms                       = this.successEnviarSms.bind(this);

        this.mostrarMensajeNoHayDatos               = this.mostrarMensajeNoHayDatos.bind(this);

        this.traerCodigoFacturacion                 = this.traerCodigoFacturacion.bind(this);
        this.successTraerFacturacion                = this.successTraerFacturacion.bind(this);
        this.validarNumeroFactura                   = this.validarNumeroFactura.bind(this);
        this.successValidaNumFactura                = this.successValidaNumFactura.bind(this);

        this.validarFechaMenorActual                = this.validarFechaMenorActual.bind(this);
        this.datosClienteModificar                  = this.datosClienteModificar.bind(this);
        this.successInfoCliente                     = this.successInfoCliente.bind(this);
        this.initInfoCliente                        = this.initInfoCliente.bind(this);
        this.limpiarMsjDeErrorInfoCliente           = this.limpiarMsjDeErrorInfoCliente.bind(this);

        this.recuperarTipoDoc                       = this.recuperarTipoDoc.bind(this);
        this.successRecuperarTipoDoc                = this.successRecuperarTipoDoc.bind(this);
        this.recuperarAgenteRet                     = this.recuperarAgenteRet.bind(this);
        this.successRecuperarAgenteRet              = this.successRecuperarAgenteRet.bind(this);
        this.recuperarPaises                        = this.recuperarPaises.bind(this);
        this.successRecuperarPaises                 = this.successRecuperarPaises.bind(this);

        this.recuperarDepto                         = this.recuperarDepto.bind(this);
        this.successRecuperarDepto                  = this.successRecuperarDepto.bind(this);
        this.recuperarCiudades                      = this.recuperarCiudades.bind(this);
        this.successRecuperarCiudades               = this.successRecuperarCiudades.bind(this);

        this.modificarMostradorCliente              = this.modificarMostradorCliente.bind(this);
        this.consumirServicioModificarMostradorCliente  = this.consumirServicioModificarMostradorCliente.bind(this);
        this.abrirModalFactura                      = this.abrirModalFactura.bind(this);

        this.initFacturar                           = this.initFacturar.bind(this);
        this.formatearCambio                        = this.formatearCambio.bind(this);
        this.habilitarCredito                       = this.habilitarCredito.bind(this);
        this.habilitarDebito                        = this.habilitarDebito.bind(this);

        this.traerAnticipo                          = this.traerAnticipo.bind(this);
        this.successtraerAnticipo                   = this.successtraerAnticipo.bind(this);
        this.setButtonSeleccionar                   = this.setButtonSeleccionar.bind(this);
        this.clickAnticipo                          = this.clickAnticipo.bind(this);

        this.gridOptionsProductos                   = Object.assign({}, this.gridOptions);
        this.gridOptionsAnticipos                   = Object.assign({}, this.gridOptions);
        this.onSelectionChanged                     = this.onSelectionChanged.bind(this);
        this.gridOptionsProductos['onSelectionChanged'] = this.onSelectionChanged;

        this.limpiarSmsErrorCamposModalFacturar     = this.limpiarSmsErrorCamposModalFacturar.bind(this);
        this.concatenarNombre                       = this.concatenarNombre.bind(this);
        this.validarCamposApellidos                 = this.validarCamposApellidos.bind(this);
        this.tipoDocumento                          = this.tipoDocumento.bind(this);

        this.consultarCodigoFacturacion             = this.consultarCodigoFacturacion.bind(this);
        this.successGuardarFact                     = this.successGuardarFact.bind(this);
        this.successPagoFactura                     = this.successPagoFactura.bind(this);
        this.aceptarFactura                         = this.aceptarFactura.bind(this);
        this.actualizarCartera                      = this.actualizarCartera.bind(this);
        this.successActualizarCartera               = this.successActualizarCartera.bind(this);

        this.campoCodigoTarjetaCredito              = this.campoCodigoTarjetaCredito.bind(this);
        this.campoCodigoTarjetaDebito               = this.campoCodigoTarjetaDebito.bind(this);
        this.limpiarCamposOtrasFormasPago           = this.limpiarCamposOtrasFormasPago.bind(this);
        this.campoValorTransferencia                = this.campoValorTransferencia.bind(this);
        this.campoValorConsignacion                 = this.campoValorConsignacion.bind(this);

        this.maneja_remision                        = false;
        this.manejaRemision                         = this.manejaRemision.bind(this);
        this.successManejaRemision                  = this.successManejaRemision.bind(this);
        this.fecha_anticipo                         =  "";
        this.saldo_anticipo1                        = 0;

        this.habilitadoEnviarMSM                    = this.habilitadoEnviarMSM.bind(this);
        this.successMsm                             = this.successMsm.bind(this);
        this.habilitadoMsm                          = false;
        this.paisIdcliente                          = 0;

        
    }

    initForm() {
        console.log('Formulario: Caja Mostrador,  @version: jdesk_1.01.0001.1, @author: Cristian Ciro');

        this.getField('sucursal_ingreso').setValue(JSON.parse(localStorage.getItem("sucursal_ingreso")).codigo_sucursal);
        let fechaActual = new Date();
        fechaActual = fechaActual.toISOString().split('T')[0];
        this.fecha_anticipo = fechaActual;
        this.getField('anticipo_caja').setValue(0);

        this.nuevoPedidosPendientes();
        this.getField('btnBuscarCliente').setClick(()=>{
            this.registroSeleccionado = '';
            this.codigoConsecutivo = 0;
            this.getField('btnBuscarCliente').setDisabled(true);
            this.nuevoPedidosPendientes();
        });

        this.traerCodigoBodegaSaldo();
        this.recuperarPaises();

        this.getField('pais_id').setOnChange(this.recuperarDepto);
        this.getField('departamento_geografico_id').setOnChange(this.recuperarCiudades);

        this.getField('btnAnticipo').setClick(()=>{
            this.getField("pedido").setDisabled(true);
            this.llenarpedido();
            this.getField("anticipo").setError(false,"");
            this.getField("numero_telefono").setError(false,"");
            this.getField("correo_usuario").setError(false,"");
            this.getField("pedido").setError(false,"");
            this.getField('modalAnticipo').handleClickOpen();
        });

        this.getField('valor_anticipo').setOnChange(()=>{
            this.saldo_anticipo=this.getField('valor_anticipo').getValue();
            this.getField('saldo_anticipo').setValue(this.saldo_anticipo + this.saldoA);
        });

        this.getField("btnGuardar").setClick(() => {
            if(this.getField("pedido").valid()){
                if(this.getField("anticipo").valid()){
                    if(this.getField("correo_usuario").valid() && this.getField("numero_telefono").valid()){
                        this.getField("correo_usuario").setError(false,""); 
                        this.getField("numero_telefono").setError(false,""); 
                        this.ActualizarDatos();
                    }
                }else{
                    this.getField("anticipo").setError(true,"El anticipo debe ser mayor a 0.")
                }
            }
        });

        this.getField('btnContinuar').setClick(this.modificarMostradorCliente);
        this.getField('btnSeleccionar').setClick(this.traerAnticipo);
        this.getField('btAplicar').setClick(()=>{
            if(this.getField('valor_anticipo').valid()){
                if(this.getField('valor_anticipo').getValue() <= this.saldoA * -1){
                    this.getField('anticipo_caja').setError(false,"");
                    this.getField('anticipo_caja').setValue(this.saldo_anticipo);
                    this.saldo_anticipo1 = this.getField('saldo_anticipo').getValue();
                    this.getField('modalSeleccionAnticipo').handleClose();
                }else{
                    this.getField('valor_anticipo').setError(true, "*El total no puede ser mayor al anticipo previamente realizado.")
                }
            }
        });

        this.getField('nombre1').setKeyUp(this.concatenarNombre);
        this.getField('nombre2').setKeyUp(this.concatenarNombre);
        this.getField('apellido1').setKeyUp(() => { this.concatenarNombre(); this.validarCamposApellidos(); });
        this.getField('apellido2').setKeyUp(() => { this.concatenarNombre(); this.validarCamposApellidos(); });
        this.getField('tipo_documento_id').setOnChange(() => { this.getField('tipo_documento_id').getValue() !== '' ? this.getField('tipo_documento_id').setError(false, '') : this.getField('tipo_documento_id').valid()});
        this.getField('nombre_tipo_doc').setOnChange(this.tipoDocumento);


        this.getField('modal_datos_cliente').setCloseButton(()=>{

            this.getField('btnContinuar').setVisible(false);
            this.gridOptions['rowData'] = [];
            this.getField('tablaClientes').toggle(false);
            this.nuevoPedidosPendientes();
        });
        this.getField('modalFacturar').setCloseButton(()=>{
            this.limpiarSmsErrorCamposModalFacturar(); 
        });

        this.getField('codigo_tarjeta_credito').setOnChange(()=>{
            this.campoCodigoTarjetaCredito();
        });
        this.getField('codigo_tarjeta_debito').setOnChange(()=>{
            this.campoCodigoTarjetaDebito();
        });
        


        this.getField('valor_transferencia').setOnBlur(()=>{
            if(this.getField('valor_transferencia').getValue() > 0){
                this.getField('banco_transferencia').setDisabled(false);// habilitamos el campo valor trasnsferencia cuando seleccionen una entidad bancaria
            }else {
                this.getField('banco_transferencia').setDisabled(true);
            }
        });

        this.getField('valor_consignacion').setOnBlur(()=>{
            if(this.getField('valor_consignacion').getValue() > 0){
                this.getField('banco_consignacion').setError(false, '');//se limpia el error de los campos relacionados
                this.getField('banco_consignacion').setDisabled(false);// habilitamos el campo valor consignacion cuando seleccionen una entidad bancaria
            }else {
                this.getField('banco_consignacion').setDisabled(true);
            }
        });

        this.getField('banco_transferencia').setOnChange(()=>{
            this.limpiarCamposOtrasFormasPago('pt');
        });

        
        this.getField('banco_consignacion').setOnChange(()=>{
            this.limpiarCamposOtrasFormasPago('pc');
        });


        this.getField('tarjeta_credito').setOnBlur(()=>{
            if(this.getField('tarjeta_credito').getValue() > 0){
                this.getField('codigo_tarjeta_credito').setDisabled(false);
            }else{
                this.getField('codigo_tarjeta_credito').setDisabled(true);
                this.getField('codigo_tarjeta_credito').setValue(' ');
                this.habilitarCredito();
                this.getField('aprobacion_tarjeta_credito').setDisabled(true);
                this.getField('aprobacion_tarjeta_credito').setValue('');
            }
        });

        this.getField('tarjeta_debito').setOnBlur(()=>{
            if(this.getField('tarjeta_debito').getValue() > 0){
                this.getField('codigo_tarjeta_debito').setDisabled(false);
            }else{
                this.getField('codigo_tarjeta_debito').setDisabled(true);
                this.getField('codigo_tarjeta_debito').setValue(' ');
                this.habilitarDebito();
                this.getField('aprobacion_tarjeta_debito').setDisabled(true);
                this.getField('aprobacion_tarjeta_debito').setValue('');
            }
        });


        this.getField('valor_transferencia').setKeyUp(()=>{

            this.campoValorTransferencia();
            this.formatearCambio();

        });

        this.getField('valor_consignacion').setKeyUp(()=>{

            this.campoValorConsignacion();
            this.formatearCambio();

        });

        this.manejaRemision();
        this.habilitadoEnviarMSM();
        this.getField('requiere_factura_electronica').setValue('S');


        this.getField('btnAceptar').setClick(this.consultarCodigoFacturacion);
    }

    habilitadoEnviarMSM(){
        this.datosConsultar = { datos: {
        } };
        this.generalFormatPmv = { tipo_servicio: 'most-clientes', operacion: 'msm_habilitado', operacion_tipo: 'consulta' };
        this.service.send({
            endpoint: this.constant.formConfiguration(),
            method: 'GET',
            body: this.datosConsultar,
            success: this.successMsm,
            error: this.error_,
            general: this.generalFormatPmv,
            showMessage:false
        });
        /*let body = {};       // body : es un objeto que contiene los parámetros que se necesitan para realizar la peticion
        this.generalFormat.nombre_accion = 'mostrador-clientes___msm_habilitado'; // nombre_accion : es el nombre que tenga el servicio en la tabla servicios_formulario
        this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method: 'GET',
                body: body,
                success: this.successMsm,
                error: this.error_,
                general: this.generalFormat
            });*/
    }


    successMsm(data){
        if(data.estado_p === 200){
            if(data.data[0].habilitado === 'S'){
                this.habilitadoMsm = true;
            }
        }
    }

    tipoDocumento() {
        if (this.getField('nombre_tipo_doc').getValue() === 'NIT') {
            this.getField('nombre_cliente').setDisabled(false);
            this.getField('nombre1').setDisabled(true);
            this.getField('nombre1').setError(false, '');
            this.getField('nombre2').setDisabled(true);
            this.getField('nombre2').setError(false, '');
            this.getField('apellido1').setDisabled(true);
            this.getField('apellido1').setError(false, '');
            this.getField('apellido2').setDisabled(true);
            this.getField('apellido2').setError(false, '');
            this.getField('nombre_cliente').setError(false, '');

            this.getField('nombre1').setValue('.');
            this.getField('nombre2').setValue('.');
            this.getField('apellido1').setValue('.');
            this.getField('apellido2').setValue('.');
        } else if (this.getField('nombre_tipo_doc').getValue() === 'CEDULA DE CIUDADANIA'){
            this.getField('nombre_cliente').setDisabled(true);
            this.getField('nombre1').setDisabled(false);
            this.getField('nombre1').setError(false, '');
            this.getField('nombre2').setDisabled(false);
            this.getField('nombre2').setError(false, '');
            this.getField('apellido1').setDisabled(false);
            this.getField('apellido1').setError(false, '');
            this.getField('apellido2').setDisabled(false);
            this.getField('apellido2').setError(false, '');
            this.getField('nombre_cliente').setError(false, '');
        }else{
            this.getField('nombre_cliente').setDisabled(true);
            this.getField('nombre1').setDisabled(false);
            this.getField('nombre1').setError(false, '');
            this.getField('nombre2').setDisabled(false);
            this.getField('nombre2').setError(false, '');
            this.getField('apellido1').setDisabled(false);
            this.getField('apellido1').setError(false, '');
            this.getField('apellido2').setDisabled(false);
            this.getField('apellido2').setError(false, '');
            this.getField('nombre_cliente').setError(false, '');

            this.getField('nombre1').setValue('.');
            this.getField('nombre2').setValue('.');
            this.getField('apellido1').setValue('.');
            this.getField('apellido2').setValue('.');
        }
    }

    validarCamposApellidos() {
        if ((this.getField('apellido1').getValue() !== '' && this.getField('apellido1').getValue() !== '.' && this.getField('apellido1').valid() && this.getField('apellido2').valid()) ||
            (this.getField('apellido2').getValue() !== '' && this.getField('apellido2').getValue() !== '.' && this.getField('apellido2').valid() && this.getField('apellido1').valid())) {
            this.getField('apellido1').setError(false, '');
            this.getField('apellido2').setError(false, '');
        } else if (((this.getField('apellido1').getValue()).replace(/\u0020/g, '') === '' || this.getField('apellido1').getValue() === '.') &&
            ((this.getField('apellido2').getValue()).replace(/\u0020/g, '') === '' || this.getField('apellido2').getValue() === '.')) {
            this.getField('apellido1').valid();
            this.getField('apellido2').valid();
        }
    }

    manejaRemision(){
        this.datosConsultar = { datos: {
        } };
        this.generalFormatPmv = { tipo_servicio: 'most-facturacion', operacion: 'remision', operacion_tipo: 'consulta' };
        this.service.send({
            endpoint: this.constant.formConfiguration(),
            method: 'GET',
            body: this.datosConsultar,
            success: this.successManejaRemision,
            error: this.error_,
            general: this.generalFormatPmv,
            showMessage:false
        });
    }

    successManejaRemision(data){
        if(data.estado_p === 200){
            if(data.data[0].maneja_remision === "S"){
                // console.log('maneja_remision está en S');
                this.maneja_remision = true;
                this.getField('valor_total_remision').setVisible(true);
                this.getField('valor_total_factura').setVisible(true);
            }else{
                // console.log('maneja_remision está en N');
                this.maneja_remision = false;
                this.getField('valor_total_remision').setVisible(false);
                this.getField('valor_total_factura').setVisible(false);
            }
        }
    }

    concatenarNombre() {
        let nom1 = this.getField('nombre1').getValue();
        let nom2 = this.getField('nombre2').getValue();
        let ape1 = this.getField('apellido1').getValue();
        let ape2 = this.getField('apellido2').getValue();
        let unionCampos = '';
        let arrayNombreFinal = [];
        let arrayUnionCampos = `${nom1},${nom2},${ape1},${ape2}`.split(/\u002C/g);// Se crea un array  partir de las comas (,), la expresión regular que tiene el "split" es del carácter coma.
        arrayUnionCampos.forEach((item) => {// La idea con este forEach es crear un arreglo sólo con nombres y apellidos con un valor diferente a punto (.)
            if (item.replace(/\u0020/g, '') !== '' && item.replace(/\u0020/g, '') !== '.') {// El replace se hace para eliminar los espacios
                arrayNombreFinal.push(item);
            }
        });
        let concatenarNombre = '';
        arrayNombreFinal.forEach((item) => {// Lo que se hace con este forEach es crear una cadena con los nombres y apellidos que están en el arreglo "arrayNombreFinal"
            concatenarNombre += `${item} `;
        });
        unionCampos = concatenarNombre.substring(0, concatenarNombre.length - 1);// Se quita el último carácter de la cadena, que para este caso sería un espacio.

        let estado = true;
        if (this.getField('nombre1').valid() && estado) {
            estado = true;
        }else{
            estado= false;
        }
        if (this.getField('nombre2').valid() && estado) {
            estado = true;
        }else{
            estado= false;
        }
        if (this.getField('apellido1').valid() && estado) {
            estado = true;
        }else{
            estado= false;
        }
        if (this.getField('apellido2').valid() && estado) {
            estado = true;
        }else{
            estado= false;
        }
        if (unionCampos.replace(/\u0020/g, '') !== '' && estado) {
            estado = true;
        }else{
            estado= false;
        }
        
        if (estado) {
            this.getField('nombre_cliente').setValue(unionCampos);
        } else if (this.getField('nombre_cliente').getValue() !== '') {
            this.getField('nombre_cliente').setValue('');
            this.getField('nombre_cliente').setError(false, '');
        }
    }

    onSelectionChanged() {
        if(this.gridOptionsProductos.api !== undefined){
            this.filaSeleccionadaGrid = this.gridOptionsProductos.api.getSelectedRows();
            this.getField('producto').setValue(this.filaSeleccionadaGrid[0].codigo);
            this.getField('nombre_marca').setValue('.');
            this.getField('marca').setValue(this.filaSeleccionadaGrid[0].marca);
            this.getField('tarifa_iva').setValue(this.filaSeleccionadaGrid[0].tarifa_iva);
            this.getField('precio').setValue(this.filaSeleccionadaGrid[0].precio);
            this.getField('precio_mas_iva').setValue(this.filaSeleccionadaGrid[0].precio_mas_iva);
            this.getField('referencia').setValue(this.filaSeleccionadaGrid[0].referencia);
            this.getField('presenta').setValue(this.filaSeleccionadaGrid[0].presenta);
            this.getField('presenta').setValue(this.filaSeleccionadaGrid[0].presenta);
            this.getField('existencia').setValue(this.filaSeleccionadaGrid[0].existencia);
            this.getField('articulo_id').setValue(this.filaSeleccionadaGrid[0].id);
            this.getField('nombre_producto').setValue(this.filaSeleccionadaGrid[0].nombre);
            this.getField('precio_incluye_iva').setValue(this.filaSeleccionadaGrid[0].precio_incluye_iva);//
            this.getField('modal_productos').handleClose();
        }
    }

    traerAnticipo(){
        this.saldo_anticipo=0;
        this.saldoA= 0;
        this.getField('anticipo_caja').setValue(0)
        this.getField('anticipo_caja').setError(false,"");
        this.getField('valor_anticipo').setError(false, "")
        this.getField('saldo_anticipo').setValue("");

        this.datosConsultar = { datos: {
            nit: this.nit,
            sede: this.sede
        } };
        this.generalFormatPmv = { tipo_servicio: 'most-clientes', operacion: 'retornaranticipo', operacion_tipo: 'consulta' };
        this.service.send({
            endpoint: this.constant.formConfiguration(),
            method: 'GET',
            body: this.datosConsultar,
            success: this.successtraerAnticipo,
            error: this.error_,
            general: this.generalFormatPmv
        });
    }

    successtraerAnticipo(data){
        if (data.estado_p === 200 && data.data.length > 0) {
            this.getField('modalSeleccionAnticipo').handleClickOpen();
            let configCell = new Map();
            configCell.set('accion', { cellRenderer: this.setButtonSeleccionar, width: 90, sortable: false, filter: false, field: 'Acción' });
            configCell.set('valor',{ cellRenderer:(props)=>{return "".formatoPrecio(props.data.valor)}, cellStyle:{height: '100%', paddingLeft: '0%', paddingTop: '0%', paddingRight: '0%', fontSize: '0.875rem', textAlign:'left'},field: 'valor'});
            configCell.set('saldo',{ cellRenderer:(props)=>{return "".formatoPrecio(props.data.saldo)}, cellStyle:{height: '100%', paddingLeft: '0%', paddingTop: '0%', paddingRight: '0%', fontSize: '0.875rem', textAlign:'left'},field: 'saldo'});
            this.gridOptionsAnticipos['rowData'] = data.data;
            
            this.getField("tablaAnticipos").initData(this.gridOptionsAnticipos, configCell);
            //this.gridOptionsProductos.api.sizeColumnsToFit();
        } else{
            this.getField("tablaAnticipos").toggle(false);
            this.getField('btnSeleccionar').setDisabled(true)
            this.alertGeneral.toggle(true, 'No hay anticipos.', "error");
        }
    }

    setButtonSeleccionar(props){
        
        let button = document.createElement("input"); 
        button.onclick = ()=>this.clickAnticipo(props);
        
        button.setAttribute("id", 'button_' + this.numero_pedido);
        button.setAttribute("class", "buttonStyle");
        button.setAttribute("type", "button");
        button.setAttribute("value", "Seleccionar");
        return this.createElementJaivana(button);
    }

    clickAnticipo(props){
        this.numero_pedido= props.data.numero_pedido;
        let fechaActual = new Date();
        fechaActual = fechaActual.toISOString().split('T')[0];
        this.fecha_anticipo= props.data.fecha;
        let saldo= props.data.saldo;
        
        this.saldoA= saldo;
        this.getField('valor_anticipo').setError(false,"");
        this.getField('valor_anticipo').setValue(saldo * -1);
        this.getField('n_pedido').setValue(this.numero_pedido);
        this.getField('fecha_anticipo').setValue(fechaActual);
        this.getField('saldo_anticipo').setValue(this.saldo_anticipo + saldo);
    }

    modificarMostradorCliente() {
        if (this.getField('nombre_tipo_doc').valid() && this.getField('nombre_tipo_doc').getValue() !== 'NIT') {
            if ((this.getField('nombre1').getValue() !== '.' && (this.getField('nombre1').getValue()).replace(/\u0020/g, '') !== '' &&
                this.getField('apellido1').getValue() !== '.' && (this.getField('apellido1').getValue()).replace(/\u0020/g, '') !== '') ||
                (this.getField('nombre1').getValue() !== '.' && (this.getField('nombre1').getValue()).replace(/\u0020/g, '') !== '' &&
                    this.getField('apellido2').getValue() !== '.' && (this.getField('apellido2').getValue()).replace(/\u0020/g, '') !== '') ||
                (this.getField('nombre2').getValue() !== '.' && (this.getField('nombre2').getValue()).replace(/\u0020/g, '') !== '' &&
                    this.getField('apellido2').getValue() !== '.' && (this.getField('apellido2').getValue()).replace(/\u0020/g, '') !== '') ||
                (this.getField('nombre2').getValue() !== '.' && (this.getField('nombre2').getValue()).replace(/\u0020/g, '') !== '' &&
                    this.getField('apellido1').getValue() !== '.' && (this.getField('apellido1').getValue()).replace(/\u0020/g, '') !== '')) {
                        this.limpiarMsjDeErrorInfoCliente();
                        this.getField('email_secundario_facturacion_electronica').setValue(this.getField('email_secundario').getValue());
                        if (this.getField('nombre1').valid() &&
                            this.getField('nombre2').valid() &&
                            this.getField('apellido1').valid() &&
                            this.getField('apellido2').valid() &&
                            this.getField('tipo_persona').valid() &&
                            this.getField('ciudad_id').valid() &&
                            this.getField('direccion').valid() &&
                            this.getField('pais_id').valid() &&
                            this.getField('departamento_geografico_id').valid() &&
                            this.getField('codigo_agente_retenedor').valid() &&
                            this.getField('tipo_documento_id').valid() &&
                            this.getField('digito_verificacion').valid() &&
                            this.getField('telefonos').valid() &&
                            this.getField('nombre_cliente').valid() &&
                            this.getField('email_principal').valid() &&
                            this.getField('requiere_factura_electronica').valid() &&
                            this.getField('total').getValue() >= 1) {
                            if (this.getField('email_principal').getValue() !== '' && this.getField('email_principal').getValue() !== '.') {
                                this.consumirServicioModificarMostradorCliente();
                            } else if (this.getField('email_secundario_facturacion_electronica').getValue() !== '' && this.getField('email_secundario_facturacion_electronica').getValue() !== '.') {
                                this.consumirServicioModificarMostradorCliente();
                            } else if ((this.getField('email_principal').getValue() === '.' || this.getField('email_principal').getValue() === '') && (this.getField('requiere_factura_electronica').getValue() === 'S')) {
                                this.getField('email_secundario').setError(true, '* Digite un email valido');
                                this.getField('email_secundario').setRules({ required: true, rule: /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9]){1,}$/, max: 100, min: 1 });
                            } else if ((this.getField('email_principal').getValue() === '.' || this.getField('email_principal').getValue() === '') &&
                                (this.getField('requiere_factura_electronica').getValue() === 'N') && this.getField('email_secundario').getValue() === '') {
                                this.getField('email_secundario').setError(true, '* Digite un email valido o un punto (.)');
                                this.getField('email_secundario').setRules({ required: true, rule: /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9]){1,}$/, max: 100, min: 1 });
                            } else if ((this.getField('email_principal').getValue() === '.' || this.getField('email_principal').getValue() === '') &&
                                (this.getField('requiere_factura_electronica').getValue() === 'N') && this.getField('email_secundario').getValue() !== '') {
                                this.consumirServicioModificarMostradorCliente();
                            }
                        } else if (this.getField('total').getValue() === 0) {
                            this.alertGeneral.toggle(true, 'El Cliente no tiene artículos asociados', "error");//Mostramos una alerta roja enviando los parametros true, 'El campo no cumple con el formato deseado', 'error'
                
                        }
                    } else {
                        this.getField('confirmModalCustom').setTitleAndContent('Error', `Mínimo debe ir un nombre con un apellido`)
                        this.getField('confirmModalCustom').setClickDialog(() => {
                            this.getField('confirmModalCustom').toggle(false);
                            this.getField('apellido1').setError(true, '* Este campo es requerido.');
                            this.getField('apellido2').setError(true, '* Este campo es requerido.');
                        });
                        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
                        this.getField("confirmModalCustom").setButtonConfirm(`Aceptar`); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
                        this.getField('confirmModalCustom').toggle(true);
                    }
        }else{
            this.limpiarMsjDeErrorInfoCliente();
            this.getField('email_secundario_facturacion_electronica').setValue(this.getField('email_secundario').getValue());
            if (this.getField('nombre1').valid() &&
                this.getField('nombre2').valid() &&
                this.getField('apellido1').valid() &&
                this.getField('apellido2').valid() &&
                this.getField('tipo_persona').valid() &&
                this.getField('ciudad_id').valid() &&
                this.getField('direccion').valid() &&
                this.getField('pais_id').valid() &&
                this.getField('departamento_geografico_id').valid() &&
                this.getField('codigo_agente_retenedor').valid() &&
                this.getField('tipo_documento_id').valid() &&
                this.getField('digito_verificacion').valid() &&
                this.getField('telefonos').valid() &&
                this.getField('nombre_cliente').valid() &&
                this.getField('email_principal').valid() &&
                this.getField('requiere_factura_electronica').valid() &&
                this.getField('total').getValue() >= 1) {
                if (this.getField('email_principal').getValue() !== '' && this.getField('email_principal').getValue() !== '.') {
                    this.consumirServicioModificarMostradorCliente();
                } else if (this.getField('email_secundario_facturacion_electronica').getValue() !== '' && this.getField('email_secundario_facturacion_electronica').getValue() !== '.') {
                    this.consumirServicioModificarMostradorCliente();
                } else if ((this.getField('email_principal').getValue() === '.' || this.getField('email_principal').getValue() === '') && (this.getField('requiere_factura_electronica').getValue() === 'S')) {
                    this.getField('email_secundario').setError(true, '* Digite un email valido');
                    this.getField('email_secundario').setRules({ required: true, rule: /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9]){1,}$/, max: 100, min: 1 });
                } else if ((this.getField('email_principal').getValue() === '.' || this.getField('email_principal').getValue() === '') &&
                    (this.getField('requiere_factura_electronica').getValue() === 'N') && this.getField('email_secundario').getValue() === '') {
                    this.getField('email_secundario').setError(true, '* Digite un email valido o un punto (.)');
                    this.getField('email_secundario').setRules({ required: true, rule: /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9]){1,}$/, max: 100, min: 1 });
                } else if ((this.getField('email_principal').getValue() === '.' || this.getField('email_principal').getValue() === '') &&
                    (this.getField('requiere_factura_electronica').getValue() === 'N') && this.getField('email_secundario').getValue() !== '') {
                    this.consumirServicioModificarMostradorCliente();
                }
            } else if (this.getField('total').getValue() === 0) {
                this.alertGeneral.toggle(true, 'El Cliente no tiene artículos asociados', "error");//Mostramos una alerta roja enviando los parametros true, 'El campo no cumple con el formato deseado', 'error'
    
            }
        }
    }

    consumirServicioModificarMostradorCliente() {
        this.getField('verifica').setValue(this.getField('digito_verificacion').getValue());
        this.getField('calidad_agente_retenedor_ventas_id').setValue(this.getField('calidad_agenteretenedor_id').getValue());
        this.datosConsultar = {
            datos:{
                id: parseInt(this.getField('id').getValue()),
                nombre1: this.getField('nombre1').getValue(),
                nombre2: this.getField('nombre2').getValue(),
                apellido1: this.getField('apellido1').getValue(),
                apellido2: this.getField('apellido2').getValue(),
                tipo_persona: this.getField('tipo_persona').getValue(),
                //codigo_ciudad: this.getField('codigo_ciudad').getValue(),
                direccion: this.getField('direccion').getValue(),
                pais_id: this.getField('pais_id').getValue(),
                departamento_geografico_id: this.getField('departamento_geografico_id').getValue(),
                ciudad_id: this.getField('ciudad_id').getValue(),
                tipo_documento_id: this.getField('tipo_documento_id').getValue(),
                verifica: this.getField('verifica').getValue(),
                calidad_agente_retenedor_ventas_id: this.getField('calidad_agente_retenedor_ventas_id').getValue(),
                telefonos: this.getField('telefonos').getValue(),
                nombre_cliente: this.getField('nombre_cliente').getValue(),
                email: this.getField('email_principal').getValue(),
                email_factura_electronica: this.getField('email_principal').getValue(),
                email_secundario_facturacion_electronica: this.getField('email_secundario_facturacion_electronica').getValue(),
                //requiere_factura_electronica: this.getField('requiere_factura_electronica').getValue()
            }
        };       // body : es un objeto que contiene los parámetros que se necesitan para realizar la peticion
        this.generalFormatPmv = { tipo_servicio: 'most-clientes', operacion: 'modificar', operacion_tipo: 'modificar' };
        this.service.send({
            endpoint: this.constant.formConfiguration(),
            method: 'PUT',
            body: this.datosConsultar,
            success: this.abrirModalFactura,
            error: this.error_,
            general: this.generalFormatPmv
        });
    }

    abrirModalFactura(data) {
        if (data.estado_p === 200) {
            this.getField('btnSeleccionar').setDisabled(false);
            this.getField('total_factura').setValue(this.getField('total').getValue());
            this.getField('modalFacturar').handleClickOpen(this.initFacturar, data.data);
            this.limpiarSmsErrorCamposModalFacturar();
            this.datoFactura = this.getField('requiere_factura_electronica').getValue();
            this.getField('cliente_requiere_factura_electronica').setValue(this.datoFactura);
        }
    }

    limpiarSmsErrorCamposModalFacturar(){
        this.getField('total_factura').setError(false, '');
        this.getField('iva').setError(false, '');
        this.getField('efectivo').setError(false, '');
        this.getField('anticipo_caja').setError(false, '');
        this.getField('cheques').setError(false, '');
        this.getField('tarjeta_credito').setError(false, '');
        this.getField('codigo_tarjeta_credito').setError(false, '');
        this.getField('aprobacion_tarjeta_credito').setError(false, '');
        this.getField('tarjeta_debito').setError(false, '');
        this.getField('codigo_tarjeta_debito').setError(false, '');
        this.getField('aprobacion_tarjeta_debito').setError(false, '');
        this.getField('otros').setError(false, '');//ya no es necesario, porque se actualizo el campo a no editable
        this.getField('devuelta').setError(false, '');
        this.getField('observaciones').setError(false, '');
        //Agregar campos otras formas de pago
        this.getField('banco_transferencia').setError(false, '');
        this.getField('banco_consignacion').setError(false, '');
        this.getField('valor_consignacion').setError(false, '');
        this.getField('valor_transferencia').setError(false, '');

        //se deshabilitan los campos de select del modal facturar
        this.getField('banco_transferencia').setDisabled(true);
        this.getField('banco_consignacion').setDisabled(true);
        this.getField('codigo_tarjeta_credito').setDisabled(true);
        this.getField('codigo_tarjeta_debito').setDisabled(true);
        this.getField('aprobacion_tarjeta_debito').setDisabled(true);
        this.getField('aprobacion_tarjeta_credito').setDisabled(true);

        //se limpian las vbariables globales

        this.hora_consignacion = '';
        this.hora_transferencia = '';
        
    }

    initFacturar(datos) {
        let horaFormateada = '';
        horaFormateada = (new Date().toString().split(' '))[4];

        this.getField('hora_consignacion').setValue(horaFormateada);
        this.getField('hora_transferencia').setValue(horaFormateada);
        //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        this.getField("btnAceptar").setDisabled(false);
        this.getField('cliente_requiere_factura_electronica').setValue(this.datoFactura);
        //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        this.getField('tarjeta_debito').setRules({})
        this.getField('tarjeta_credito').setRules({})

        this.getField('modal_datos_cliente').handleClose();
        this.limpiarMsjDeErrorInfoCliente();
        /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        this.getField('efectivo').setValue(0);
        this.getField('anticipo_caja').setValue(0);
        this.getField('anticipo_caja').setError(false,"");

        this.getField('cheques').setValue(0);
        this.getField('tarjeta_credito').setValue(0);
        this.getField('tarjeta_debito').setValue(0);
        this.getField('otros').setValue(0);

        this.getField('valor_transferencia').setValue(0);
        this.getField('valor_consignacion').setValue(0);


        this.getField('efectivo').setKeyUp(this.formatearCambio);
        this.getField('anticipo_caja').setOnChange(this.formatearCambio);
        this.getField('cheques').setKeyUp(this.formatearCambio);
        this.getField('tarjeta_credito').setKeyUp(()=>{

            this.formatearCambio();
        });
        this.getField('tarjeta_debito').setKeyUp(()=>{
 
            this.formatearCambio();
        });
        //se descomenta ya que en JBAS-2964 se pidio manejar como campo independiente
        this.getField('otros').setKeyUp(this.formatearCambio); // se comenta esta linea ya que en este requerimiento se pidio que el campo otros no fuera editable

        this.getField('codigo_tarjeta_credito').setOnBlur(()=>{
            this.habilitarCredito();
        });

        this.getField('codigo_tarjeta_debito').setOnBlur(()=>{
            this.habilitarDebito();
        });

        this.getField('devuelta').setValue(0);
        if (datos.total !== undefined)
            this.getField('total_factura').setValue(datos.total.toFixed(2));
        if (datos.iva !== undefined)
            this.getField('iva').setValue(datos.iva.toFixed(2));
    }

    formatearCambio() {
        let efectivo = (!isNaN(parseFloat(this.getField('efectivo').getValue()))) ? parseFloat(this.getField('efectivo').getValue()) : 0;
        let anticipo_caja = (!isNaN(parseFloat(this.getField('anticipo_caja').getValue()))) ? parseFloat(this.getField('anticipo_caja').getValue()) : 0;
        let cheque = (!isNaN(parseFloat(this.getField('cheques').getValue()))) ? parseFloat(this.getField('cheques').getValue()) : 0;
        let tarjeta_nro1 = (!isNaN(parseFloat(this.getField('tarjeta_debito').getValue()))) ? parseFloat(this.getField('tarjeta_debito').getValue()) : 0;
        let tarjeta_nro2 = (!isNaN(parseFloat(this.getField('tarjeta_credito').getValue()))) ? parseFloat(this.getField('tarjeta_credito').getValue()) : 0;
        let otros = (!isNaN(parseFloat(this.getField('otros').getValue()))) ? parseFloat(this.getField('otros').getValue()) : 0;
        let valor_consignacion = (!isNaN(parseFloat(this.getField('valor_consignacion').getValue()))) ? parseFloat(this.getField('valor_consignacion').getValue()) : 0; // campos nuevos agragados en el modal de facturar
        let valor_transferencia = (!isNaN(parseFloat(this.getField('valor_transferencia').getValue()))) ? parseFloat(this.getField('valor_transferencia').getValue()) : 0; // campos nuevos agragados en el modal de facturar

        let cambio2 = this.totalFactura - (efectivo + anticipo_caja + cheque + otros + tarjeta_nro1 + tarjeta_nro2 + valor_consignacion + valor_transferencia);
        //let nuevoTotal = this.totalFactura - efectivo
        if (this.getField('tarjeta_debito').getValue() > 0) {
            this.validDebito = true;
            this.getField('codigo_tarjeta_debito').setRules({ required: true })
            this.getField('aprobacion_tarjeta_debito').setRules({ required: true, rule: /^([0-9A-Za-z-]{1,30})?$/ })
        } else {
            this.validDebito = false;
            this.getField('codigo_tarjeta_debito').setRules({})
            this.getField('aprobacion_tarjeta_debito').setRules({})
        }

        if (this.getField('tarjeta_credito').getValue() > 0) {
            this.validCredito = true;
            this.getField('codigo_tarjeta_credito').setRules({ required: true })
            this.getField('aprobacion_tarjeta_credito').setRules({ required: true, rule: /^([0-9A-Za-z-]{1,30})?$/ })
        } else {
            this.validCredito = false;
            this.getField('codigo_tarjeta_credito').setRules({})
            this.getField('aprobacion_tarjeta_credito').setRules({})
        }
        this.getField('devuelta').setValue(cambio2);
        if (cambio2 > 0)
            this.getField('total_factura').setValue(cambio2.toFixed(2));
        else {
            this.getField('total_factura').setValue(0);
        }
        let cambio = (efectivo + anticipo_caja+ cheque + otros + tarjeta_nro1 + tarjeta_nro2 +valor_consignacion + valor_transferencia) - this.totalFactura;
        if (cambio > 0)
            this.getField('devuelta').setValue(cambio.toFixed(2));
        else
            this.getField('devuelta').setValue(0);
    }

    habilitarCredito() {
        if (this.getField('codigo_tarjeta_credito').getValue() !== '') {
            this.validCredito = true;
            this.getField('tarjeta_credito').setRules({ min: 1 })
            this.getField('aprobacion_tarjeta_credito').setRules({ required: true, rule: /^([0-9A-Za-z-]{1,30})?$/ })
        } else {
            this.validCredito = false;
            this.getField('tarjeta_credito').setRules({ min: 0 })
            this.getField('tarjeta_credito').setError(false, '');
            this.getField('aprobacion_tarjeta_credito').setError(false, '');
            this.getField('aprobacion_tarjeta_credito').setRules({})
            this.getField('tarjeta_credito').setValue(0);
            this.getField('aprobacion_tarjeta_credito').setValue('');
        }
        this.formatearCambio();
    }

    habilitarDebito() {
        if (this.getField('codigo_tarjeta_debito').getValue() !== '') {
            this.validDebito = true;
            this.getField('tarjeta_debito').setRules({ min: 1 })
            this.getField('aprobacion_tarjeta_debito').setRules({ required: true, rule: /^([0-9A-Za-z-]{1,30})?$/ })
        } else {
            this.validDebito = false;
            this.getField('tarjeta_debito').setRules({ min: 0 })
            this.getField('tarjeta_debito').setValue(0);
            this.getField('tarjeta_debito').setError(false, '');
            this.getField('aprobacion_tarjeta_debito').setRules({})
            this.getField('aprobacion_tarjeta_debito').setValue('');
            this.getField('aprobacion_tarjeta_debito').setError(false, '');
        }
        this.formatearCambio();
    }

    recuperarDepto() {
        // Limpieza del select de ciudades al cambiar de departamento
        this.getField('ciudad_id').setOptions([{ text: 'Ninguno', value: '' }]);
        this.getField('departamento_geografico_id').setOptions([{ text: 'Ninguno', value: '' }]);
    
        if (this.getField('pais_id').getValue() > 0) {
            this.datosConsultar = {
                datos: { pais_id: this.getField('pais_id').getValue() }
            };
            this.generalFormatPmv = {tipo_servicio: 'most-deptosgeograficos',operacion: 'paisid',operacion_tipo: 'consulta'};
    
            this.service.send({
                endpoint: this.constant.formConfiguration(),
                method: 'GET',
                body: this.datosConsultar,
                success: this.successRecuperarDepto,
                error: this.error_,
                general: this.generalFormatPmv
            });
        }
    }
    
    successRecuperarDepto(data) {
        let opciones = [{ text: 'Ninguno', value: '' }];
        if (data.data.length > 0) {
            data.data.forEach(item => {
                opciones.push({ value: item.id, text: item.nombre });
            });
        }

        // Actualizar opciones del select de departamento
        this.getField('departamento_geografico_id').setOptions(opciones);
        
        if(parseInt(this.getField("pais_id").getValue()) === this.paisIdcliente && this.inicio === false){
            this.getField('departamento_geografico_id').setValue(this.deptoIdCliente);
        }
        this.inicio = false;
    }
    
    recuperarCiudades() {
        console.log("entro a ciudades");
        if (this.getField('departamento_geografico_id').getValue() > 0) {
            this.datosConsultar = {
                datos: { departamento_geografico_id_ciudades1: parseInt(this.getField('departamento_geografico_id').getValue())}
            };
            this.generalFormatPmv = {tipo_servicio: 'most-ciudades',operacion: 'deptoid',operacion_tipo: 'consulta'};
    
            this.service.send({
                endpoint: this.constant.formConfiguration(),
                method: 'GET',
                body: this.datosConsultar,
                success: this.successRecuperarCiudades,
                error: this.error_,
                general: this.generalFormatPmv
            });
        }
    }
    
    successRecuperarCiudades(data) {
        let opciones = [{ text: 'Ninguno', value: '' }];
        if (data.data.length > 0) {
            data.data.forEach(item => {
                opciones.push({ value: item.id, text: item.nombre });
            });
        }
    
        // Actualizar opciones del select de ciudad
        this.getField('ciudad_id').setOptions(opciones);

        if(parseInt(this.getField("pais_id").getValue() === this.paisIdcliente)){
            this.getField('ciudad_id').setValue(this.ciudadIdCliente);
        }
    }
    
    recuperarPaises() {
        this.datosConsultar = { datos: {} };
        this.generalFormatPmv = { tipo_servicio: 'most-paises', operacion: 'todos', operacion_tipo: 'consulta' };
        this.service.send({
            endpoint: this.constant.formConfiguration(),
            method: 'GET',
            body: this.datosConsultar,
            success: this.successRecuperarPaises,
            error: this.error_,
            general: this.generalFormatPmv
        });
    }
    
    successRecuperarPaises(data) {
        let opciones = [{ text: 'Ninguno', value: '', 'campos_cambian': {} }];
    
        if (data.estado_p === 200 && data.data.length > 0) {
            opciones = opciones.concat(
                data.data.filter(item => item.estado === 'A').map(item => ({
                    value: item.id,
                    text: item.nombre,
                    campos_cambian: { codigo_pais: item.codigo_pais, codigo_dian: item.codigo_dian }
                }))
            );
        }
    
        this.getField('pais_id').setOptions(opciones);
        this.setOptionsVacias('departamento_geografico_id');
        this.setOptionsVacias('ciudad_id');
    }
    
    // Función para vaciar opciones
    setOptionsVacias(campo) {
        this.getField(campo).setOptions([{ text: 'Ninguno', value: '', 'campos_cambian': {} }]);
    }    

    mostrarMensajeGenerando(){
        this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está generando la información... `);
        this.getField('confirmModalCustom').setClickDialog(()=>{});  
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
    }

    ActualizarDatos(){
        this.mostrarMensajeGenerando(); 

        this.datosConsultar = { datos: {
            nit: this.getField("documento_cliente").getValue(),   // body : es un objeto que contiene los parámetros que se necesitan para realizar la peticion
            sede: parseInt(this.getField("sede_cliente_pedido").getValue()),
            email_factura_electronica:this.getField("correo_usuario").getValue(),
            telefonos:this.getField("numero_telefono").getValue(),
            nitsx_sedes_id:this.cliente_id,
            numero_pedido: parseInt(this.getField("pedido").getValue()),
            anticipo:parseInt(this.getField("anticipo").getValue())
        } };
        this.generalFormatPmv = { tipo_servicio: 'most-clientes', operacion: 'modificadatosanticipo', operacion_tipo: 'modificar' };
        this.service.send({
            endpoint: this.constant.formConfiguration(),
            method: 'PUT',
            body: this.datosConsultar,
            success: this.successActualizar,
            error: this.error_,
            general: this.generalFormatPmv
        });
    }

    successActualizar(data){
        if (data.estado_p === 200) { // si estado_p === 200, se debe mostrar el confirm dialog para agregar el producto
            if(this.getField("correo_usuario").getValue() !== "" && this.getField("numero_telefono").getValue() !== ""){
                this.enviarCorreo();
                if(this.habilitadoMsm){
                    this.enviarsms()
                }
            }
        } else { // sino se debe mostrar el dialog de descuento no permitido
            this.getField('confirmModalCustom').toggle(false);
            this.alertGeneral.toggle(true,data.data.mensaje,"error");
        } 
    }

    enviarCorreo(){ 
        this.datosConsultar = { datos: {
            sucursal_ingreso:JSON.parse(localStorage.getItem("sucursal_ingreso")).codigo_sucursal,
            operacion:"pdf",
            email:this.getField('correo_usuario').getValue(),
            radio_correo:"S",
            cliente_id:this.cliente_id,
            numero_pedido: this.getField("pedido").getValue(),
            estado:this.estado_pedido,
            total:this.getField("total_pedido").getValue(),
            anticipo:this.getField("anticipo").getValue(),
            telefono:this.getField("numero_telefono").getValue()
        } };
        this.generalFormatPmv = { tipo_servicio: 'most-clientes', operacion: 'enviarpdfcorreoanticipo', operacion_tipo: 'consulta' };
        this.service.send({
            endpoint: this.constant.formConfiguration(),
            method: 'GET',
            body: this.datosConsultar,
            success: this.successEnviarCorreo,
            error: this.error_,
            general: this.generalFormatPmv
        });  
    }

    successEnviarCorreo(data){
        this.getField('confirmModalCustom').toggle(false);
        if(data.estado_p === 200){
            this.generarPdfTabla();
        }else if(data.estado_p ===404){
            this.mostrarMensajeNoHayDatos();
        }else{
            this.getField('confirmModalCustom').toggle(false);
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
        }
    }

    generarPdfTabla(){
        this.mostrarMensajeGenerando();

        let url = this.constant.formConfiguration();
        url = url.replace('pmvlogin','pmv');

        let body={
            sucursal_ingreso:JSON.parse(localStorage.getItem("sucursal_ingreso")).codigo_sucursal,
            operacion:"pdf",
            email:this.getField('correo_usuario').getValue(),
            radio_correo:"N",
            cliente_id:this.cliente_id,
            numero_pedido: this.getField("pedido").getValue(),
            estado:this.estado_pedido,
            total:this.getField("total_pedido").getValue(),
            anticipo:this.getField("anticipo").getValue(),
            telefono:this.getField("numero_telefono").getValue()
        };

        const myJSON = JSON.stringify(body);
        let datos='?body={"datos":'+myJSON+',"generales":{"tipo_servicio":"most-clientes","operacion":"enviarpdfcorreoanticipo","operacion_tipo":"consulta"}}';
        fetch(url+datos,{
        method:'GET', 
        headers:new Headers({
                "Authorization": 'Bearer '+localStorage.getItem('token'), 
                "Content-Type":'application/json'})})
        .then(response => {
        this.getField('confirmModalCustom').toggle(false);
        if(response.status!==200){
            return '';
        }
        const reader = response.body.getReader();
        return new ReadableStream({
        start(controller) {
        return pump();
        function pump() {
                return reader.read().then(({ done, value }) => {
                if (done) {
                    controller.close();
                    return;}
                controller.enqueue(value);
                return pump();});}}})})
        .then(stream => new Response(stream))
        .then(response => response.blob())
        .then(blob => {
            if(blob.size>0){
                blob.text().then(res=>{
                    if(this.isJson(res)){
                        this.successEnviarCorreo(JSON.parse(res));
                    }else{
                        const pdf = new File([blob], 'listaClientesPedidos.pdf', {
                            type:'application/pdf'});
                        window.open(URL.createObjectURL(pdf));
                        this.getField('modalAnticipo').handleClose();
                    } 
                });
            }else{
                this.mostrarMensajeNoHayDatos();                
            }
        }).catch(err => {
            this.alertGeneral.toggle(true,err,'error');
        });
    }

    enviarsms(){   
        this.datosConsultar = { datos: {
            sucursal_ingreso:JSON.parse(localStorage.getItem("sucursal_ingreso")).codigo_sucursal,
            cliente_id:this.cliente_id,
            numero_pedido: this.getField("pedido").getValue(),
            estado:this.estado_pedido,
            total:this.getField("total_pedido").getValue(),
            anticipo:this.getField("anticipo").getValue(),
            telefono:this.getField("numero_telefono").getValue()
        } };
        this.generalFormatPmv = { tipo_servicio: 'most-clientes', operacion: 'enviarsmsanticipo', operacion_tipo: 'consulta' };
        this.service.send({
            endpoint: this.constant.formConfiguration(),
            method: 'GET',
            body: this.datosConsultar,
            success: this.successEnviarSms,
            error: this.error_,
            general: this.generalFormatPmv,
            showMessage: false
        });  
    }

    successEnviarSms(data){
        this.getField('confirmModalCustom').toggle(false);
        if(data.estado_p === 200){
        }else if(data.estado_p ===404){
            this.mostrarMensajeNoHayDatos();
        }else{
            this.getField('confirmModalCustom').toggle(false);
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
        }
    }

    isJson(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

    llenarpedido(){
        this.datosConsultar = { datos: {
            codigo_sucursal: this.getField('sucursal_ingreso').getValue()
        } };
        this.generalFormatPmv = { tipo_servicio: 'most-productos', operacion: 'traerpedido', operacion_tipo: 'consulta' };
        this.service.send({
            endpoint: this.constant.formConfiguration(),
            method: 'GET',
            body: this.datosConsultar,
            success: this.successLlenarPedido,
            error: this.error_,
            general: this.generalFormatPmv
        });
    }

    successLlenarPedido(data){
        if (data.estado_p === 200) {

            let opciones = [{ 'text': 'Ninguno', 'value': '', 'campos_cambian': {} }];
            data.data.forEach(item => {
                let dataOp = {}
                dataOp['value'] = item.numero_pedido;
                dataOp['text'] = item.pedido_nombre;
                dataOp['campos_cambian'] = {/* separador_select: item.valor */ };
                opciones.push(dataOp);
            });
            opciones.sort();
            this.getField('pedido').setOptions(opciones);
            this.getField('pedido').setDisabled(false);
            this.getField('pedido').setOnChange(this.TraerDatosClienteFinal);

        }else {
            let respuesta = Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
        }
    }

    TraerDatosClienteFinal(){
        this.getField("correo_usuario").setError(false,"");
        this.getField("numero_telefono").setError(false,"");
        this.getField("anticipo").setError(false,"");
        if(this.getField('pedido').getValue() !== '' && this.getField('pedido').getValue() !== ' '){
            this.mostrarMensajeGenerando(); 

            this.datosConsultar = { datos: {
                numero_pedido: parseInt(this.getField('pedido').getValue()),
                codigo_sucursal: this.getField('sucursal_ingreso').getValue()
            } };
            this.generalFormatPmv = { tipo_servicio: 'most-clientes', operacion: 'traerdatos', operacion_tipo: 'consulta' };
            this.service.send({
                endpoint: this.constant.formConfiguration(),
                method: 'GET',
                body: this.datosConsultar,
                success: this.successTraerDatosClienteFinal,
                error: this.error_,
                general: this.generalFormatPmv
            });
        }else{
            this.getField("documento_cliente").setValue("");
            this.getField("nombre_cliente_pedido").setValue("");
            this.getField("sede_cliente_pedido").setValue("");
            this.getField("total_pedido").setValue("");
            this.getField("correo_usuario").setValue("");
            this.getField("numero_telefono").setValue("");
            this.getField("anticipo").setValue("");
        }
    }

    successTraerDatosClienteFinal(data){
        this.getField('confirmModalCustom').toggle(false);
        if (data.estado_p === 200) { // si estado_p === 200, se debe mostrar el confirm dialog para agregar el producto
            this.getField("pedido").setError(false,"");
            this.cliente_id=data.data[0].nitsx_sedes_id;
            this.estado_pedido=data.data[0].estado;
            this.getField("documento_cliente").setValue(data.data[0].nit);
            this.getField("nombre_cliente_pedido").setValue(data.data[0].nombre);
            this.getField("sede_cliente_pedido").setValue(data.data[0].sede);
            this.getField("total_pedido").setValue(data.data[0].total);
            if(data.data[0].email_factura_electronica === null){
                this.getField("correo_usuario").setValue('noreply@gmail.com');
            }else{
                this.getField("correo_usuario").setValue(data.data[0].email_factura_electronica);
            }
            this.getField("numero_telefono").setValue(data.data[0].telefonos);
        } else { // sino se debe mostrar el dialog de descuento no permitido
            this.getField('confirmModalCustom').toggle(false);
            this.alertGeneral.toggle(true,data.data.mensaje,"error");
        }
    }

    traerCodigoBodegaSaldo(){
        this.datosConsultar = { datos: {
            codigo_sucursal: this.getField('sucursal_ingreso').getValue()
        } };
        this.generalFormatPmv = { tipo_servicio: 'most-productos', operacion: 'saldobodegasproductos', operacion_tipo: 'consulta' };
        this.service.send({
            endpoint: this.constant.formConfiguration(),
            method: 'GET',
            body: this.datosConsultar,
            success: this.succesTraerCodBodegaSaldo,
            error: this.error_,
            general: this.generalFormatPmv
        });
    }

    succesTraerCodBodegaSaldo(data) {
        if (data.estado_p === 200) {
            let saldo_bodegas = data.data[0].saldo_bodegas_productos;
            let arrayBodegas = [];
            if (saldo_bodegas.includes(',')) {
                arrayBodegas = saldo_bodegas.split(',');
                this.saldo_bodegas_productos = arrayBodegas[0];
            } else {
                this.saldo_bodegas_productos = data.data[0].saldo_bodegas_productos;
            }
        }
    }

    nuevoPedidosPendientes() {
        this.arrayNuevo = [];
        this.getField('cliente').setValue('');
        this.getField('sede_cliente').setValue('');
        this.getField('nombre_cliente').setValue('');

        if (localStorage.getItem('sucursal_ingreso')) {
            this.datosConsultar = { datos: {
                codigo_sucursal: JSON.parse(localStorage.getItem('sucursal_ingreso'))["codigo_sucursal"]
            } };
            this.generalFormatPmv = { tipo_servicio: 'most-clientes', operacion: 'traerencabezadopedidoscierre', operacion_tipo: 'consulta' };
            this.service.send({
                endpoint: this.constant.formConfiguration(),
                method: 'GET',
                body: this.datosConsultar,
                success: this.successNuevoPendientes,
                error: this.error_,
                general: this.generalFormatPmv
            });

        } else {

            this.datosConsultar = { datos: {
                codigo_sucursal: JSON.parse(localStorage.getItem('sucursal_ingreso'))["codigo_sucursal"]
            } };
            this.generalFormatPmv = { tipo_servicio: 'most-clientes', operacion: 'resumen', operacion_tipo: 'consulta' };
            this.service.send({
                endpoint: this.constant.formConfiguration(),
                method: 'GET',
                body: this.datosConsultar,
                success: this.mostrarClientes,
                error: this.error_,
                general: this.generalFormatPmv
            });
        }
    }

    successNuevoPendientes(data) {
        if (data.estado_p === 200) {
            this.arrayPendientes = data.data; // almacenar el valor del primer servicio en un array global
            this.datosConsultar = { datos: {
                codigo_sucursal: JSON.parse(localStorage.getItem('sucursal_ingreso'))["codigo_sucursal"]
            } };
            this.generalFormatPmv = { tipo_servicio: 'most-clientes', operacion: 'traeritemspedidos', operacion_tipo: 'consulta' };
            this.service.send({
                endpoint: this.constant.formConfiguration(),
                method: 'GET',
                body: this.datosConsultar,
                success: this.susccessRespuestaSegundoServ,
                error: this.error_,
                general: this.generalFormatPmv
            });
        }else{
            this.getField('btnBuscarCliente').setDisabled(false);
        }
    }

    susccessRespuestaSegundoServ(data) {
        if (data.estado_p === 200) {
            this.arrayPendientes.forEach((item) => {
                let objetoNuevo = {
                    numero_pedido: item.numero_pedido,
                    nombre_cliente: item.nombre,
                    documento: item.nit,
                    sede: item.sede,
                    id: item.nitsx_sedes_id,
                    total: item.sum,
                    tipo_pedido: item.tipo_pedido
                }

                data.data.forEach((item2) => {
                    if(item.nitsx_sedes_id === item2.nitsx_sedes_id){
                        objetoNuevo['codigo_usuario'] = item2.codigo;
                        objetoNuevo['fecha'] = item2.fecha_sistema;
                    }
                });
                this.arrayNuevo.push(objetoNuevo);

            });

            let data1 = {
                "data" : this.arrayNuevo,
                "estado_p": data.estado_p
            };
            
            this.getField('btnBuscarCliente').setDisabled(false);
            this.mostrarClientes(data1);
        }else{
            this.getField('btnBuscarCliente').setDisabled(false);
        }

    }

    mostrarClientes(data) {
        this.contDevolver = 0;
        this.arrayIdsEliminar = 0;
        this.getField('producto').setError(false, '');
        
        this.getField('confirmModalCustom').toggle(false);
        if (data.estado_p === 200 || data.length > 0) {
            let configCell = new Map();
            configCell.set('habilitar_pedido', { cellRenderer: this.setButtonHabilitarPedido, width: 132, sortable: false, filter: false, field: 'habilitar_pedido' });
            configCell.set('facturar', { cellRenderer: this.setButtonFacturarProducto, width: 90, sortable: false, filter: false, field: 'facturar' });
            configCell.set('numero_pedido', { cellRenderer: this.selectionNumeroPedido, cellStyle: this.selectionRegistro2,width: 200, field: 'numero_pedido' });
            configCell.set('codigo_usuario', { cellRenderer: this.selectionRegistro, 
                cellStyle: this.selectionRegistro2,
                width: 200, field: 'codigo_usuario' });
            configCell.set('nombre_cliente', { cellRenderer: this.seleccionCliente,cellStyle: this.selectionRegistro2, width: 233, field: 'nombre_cliente' });
            configCell.set('documento', { cellRenderer: this.seleccionDocumento,cellStyle: this.selectionRegistro2, width: 131, field: 'documento' });
            configCell.set('sede', { cellRenderer: this.seleccionSede, cellStyle: this.selectionRegistro2,width: 90, field: 'sede' });
            configCell.set('fecha', { cellRenderer: this.seleccionFecha, cellStyle: this.selectionRegistro2,width: 110, field: 'fecha' });
            configCell.set('total', { cellRenderer: this.seleccionTotal, cellStyle: this.selectionRegistro2,width: 200, field: 'total' });
            configCell.set('tipo_pedido',{ cellRenderer: this.seleccionTipoPedido, cellStyle: this.selectionRegistro2,width:200, field: 'tipo_pedido' });
            configCell.set('devolver_todo', { cellRenderer: this.setButtonDevolver, width: 110, sortable: false, filter: false, field: 'devolver_todo' });

            this.gridOptions['rowData'] = data.estado_p === 200 ? data.data : data;
            this.getField('tablaClientes').initData(this.gridOptions, configCell);
        } else if (data.estado_p === 404 || data.length === 0) {
            this.alertGeneral.toggle(true, 'No hay pedidos pendientes', "error");
            this.getField('tablaClientes').toggle(false);
        }
    }

    setButtonHabilitarPedido(props) {
        let id = props.data.id;
        let button = document.createElement("input");
        button.onclick = () => this.habilitarPedido(props);
        button.setAttribute("id", 'button_avaible_' + id);
        button.setAttribute("type", "button");
        button.setAttribute("value", "Habilitar Pedido");
        button.setAttribute("class", "buttonStyle");
        return this.createElementJaivana(button);
    }

    habilitarPedido(props){
        this.getField('confirmModalCustom').setTitleAndContent('Habilitar Pedido', `¿Desea habilitar el pedido?`)
        this.getField('confirmModalCustom').setClickDialog(() => {this.confirmHabilitarPedido(props)});
        this.getField("confirmModalCustom").setVisibleCancel(false); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm(`Aceptar`); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
    }

    confirmHabilitarPedido(props){

        this.gridOptions['rowData'] = [];
        this.getField('tablaClientes').toggle(false);
        this.getField('confirmModalCustom').toggle(false);

        this.datosConsultar = { datos: {
            numero_pedido: parseInt(props.data.numero_pedido),
            codigo_sucursal: this.getField("sucursal_ingreso").getValue(),
            estado:"P"
        } };
        this.generalFormatPmv = { tipo_servicio: 'most-clientes', operacion: 'cerrarpedido', operacion_tipo: 'consulta' };
        this.service.send({
            endpoint: this.constant.formConfiguration(),
            method: 'PUT',
            body: this.datosConsultar,
            success: this.successHabilitarPedido,
            error: this.error_,
            general: this.generalFormatPmv
        });
    }

    successHabilitarPedido(data){
        this.nuevoPedidosPendientes();
    }

    setButtonFacturarProducto(props) {
        let id = props.data.id;
        let div = document.createElement("div");
        let button = document.createElement("input");
        button.onclick = () => this.traerCodigoFacturacion(props);
        button.setAttribute("id", 'button_facturar_' + id);
        div.setAttribute('id', 'div_facturar_' + id);
        button.setAttribute("class", "buttonStyle");
        button.setAttribute("type", "button");
        button.setAttribute("value", "Facturar");
        div.appendChild(button);
        return this.createElementJaivana(div);
    }

    selectionNumeroPedido(props) {
        let numero_pedido = props.data.numero_pedido;
        if (props.data.numero_pedido === this.codigoConsecutivo) {
            let div = document.createElement('div');
            div.setAttribute("class", "divStyle10");
            div.textContent = numero_pedido;
            return this.createElementJaivana(div);
        } else {
            let div = document.createElement('div');
            div.setAttribute("class", "divStyle11");
            div.textContent = numero_pedido;
            return this.createElementJaivana(div);
        }
    }

    selectionRegistro2(props){
        if (this.registroSeleccionado !== '' && props.data.documento === this.registroSeleccionado && props.data.numero_pedido === this.codigoConsecutivo)
            return {height: '100%', width: '200px', position: 'fixed', paddingRight: '0%', paddingLeft: '0%', paddingTop: '0%',fontSize: '0.875rem', textAlign:'left', color: '#0000ff', fontWeight: 'bold', backgroundColor: '#B7E4FF'}
        else 
            return {height: '100%', paddingLeft: '0%', paddingTop: '0%', paddingRight: '0%', fontSize: '0.875rem', textAlign:'left'}
    }

    selectionRegistro(props) {
        let codigo_usuario = props.data.codigo_usuario;
        let div = document.createElement('div');
        div.textContent = codigo_usuario;
        return this.createElementJaivana(div);
    }

    seleccionCliente(props){
        let nombre_cliente = props.data.nombre_cliente;
        if (this.registroSeleccionado !== '' && props.data.documento === this.registroSeleccionado && props.data.numero_pedido === this.codigoConsecutivo) {
            let div = document.createElement('div');
            div.setAttribute("class", "height: 100%; width: 233px; position: fixed; padding-right: 0%;padding-left: 0%;padding-top: 0%;font-size: 0.875rem;text-align:left;color: #0000ff; font-weight: bold; background-color: #B7E4FF;");
            div.textContent = nombre_cliente;
            return this.createElementJaivana(div);
        } else {
            let div = document.createElement('div');
            div.setAttribute("class", "divStyle11");
            div.textContent = nombre_cliente;
            return this.createElementJaivana(div);
        }
    }

    seleccionDocumento(props){
        let documento = props.data.documento;
        if (this.registroSeleccionado !== '' && props.data.documento === this.registroSeleccionado && props.data.numero_pedido === this.codigoConsecutivo) {
            let div = document.createElement('div');
            div.setAttribute("class", "divStyle10");
            div.textContent = documento;
            return this.createElementJaivana(div);
        } else {
            let div = document.createElement('div');
            div.setAttribute("class", "divStyle11");
            div.textContent = documento;
            return this.createElementJaivana(div);
        }
    }

    seleccionSede(props){
        let sede = props.data.sede;
        if (this.registroSeleccionado !== '' && props.data.documento === this.registroSeleccionado && props.data.numero_pedido === this.codigoConsecutivo) {
            let div = document.createElement('div');
            div.setAttribute("class", "divStyle10");
            div.textContent = sede;
            return this.createElementJaivana(div);
        } else {
            let div = document.createElement('div');
            div.setAttribute("class", "divStyle11");
            div.textContent = sede;
            return this.createElementJaivana(div);
        }
    }

    seleccionFecha(props){
        let fecha = props.data.fecha;
        if (this.registroSeleccionado !== '' && props.data.documento === this.registroSeleccionado && props.data.numero_pedido === this.codigoConsecutivo) {
            let div = document.createElement('div');
            div.setAttribute("class", "divStyle10");
            div.textContent = fecha;
            return this.createElementJaivana(div);
        } else {
            let div = document.createElement('div');
            div.setAttribute("class", "divStyle11");
            div.textContent = fecha;
            return this.createElementJaivana(div);
        }
    }

    seleccionTotal(props){
        let total = props.data.total;
        if (this.registroSeleccionado !== '' && props.data.documento === this.registroSeleccionado && props.data.numero_pedido === this.codigoConsecutivo) {
            let div = document.createElement('div');
            div.setAttribute("class", "divStyle10");
            div.textContent = "".formatoPrecio(total);
            
            return this.createElementJaivana(div);
        } else {
            let div = document.createElement('div');
            div.setAttribute("class", "divStyle11");
            div.textContent = "".formatoPrecio(total);//total;
            return this.createElementJaivana(div);
        }
    }

    seleccionTipoPedido(props){
        let tipo_pedido = props.data.tipo_pedido;
        if (this.registroSeleccionado !== '' && props.data.documento === this.registroSeleccionado && props.data.numero_pedido === this.codigoConsecutivo) {
            let div = document.createElement('div');
            div.setAttribute("class", "divStyle10");
            div.textContent = tipo_pedido;
            
            return this.createElementJaivana(div);
        } else {
            let div = document.createElement('div');
            div.setAttribute("class", "divStyle11");
            div.textContent = tipo_pedido;//total;
            return this.createElementJaivana(div);
        }
    }

    setButtonDevolver(props) {
        let button = document.createElement("input");
        button.onclick = () => this.confirmDevolverTodo(props);
        button.setAttribute("id", 'button_delete_' + props.id);
        button.setAttribute("class", "buttonStyle");//,"font-weight: 500;line-height: 1.75;font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;border-radius: 4px;font-size: 0.875rem; box-shadow:0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12) ;width:100%;text-transform: uppercase; height: 100%; background-color:#303f9f;color:#fff;border:1px solid #303f9f;padding-left:5px;padding-rigth:5px;padding-top:2px;padding-bottom:2px;border-radius:3px; cursor:pointer");
        button.setAttribute("type", "button");
        button.setAttribute("value", "Devolver todo");
        return this.createElementJaivana(button);
    }

    confirmDevolverTodo(props) {
        this.ids = props.data.id;
        this.codigoConsecutivo = props.data.numero_pedido;

        this.getField('confirmModalCustom').setTitleAndContent('Se eliminarán todos los productos del pedido', 'Desea realizar esta operación?');
        this.getField('confirmModalCustom').setClickDialog(this.consultarItemsDevolver);
        this.getField("confirmModalCustom").setVisibleCancel(false); // Para mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm(`Confirmar`); 
        this.getField('confirmModalCustom').toggle(true);

    }

    consultarItemsDevolver() {

        this.getField('cliente').setDisabled(true);

        this.datosConsultar = { datos: {
            cliente_id: this.ids,   // body : es un objeto que contiene los parámetros que se necesitan para realizar la peticion
            bodega: this.saldo_bodegas_productos,
            numero_pedido: this.codigoConsecutivo,
            codigo_sucursal: JSON.parse(localStorage.getItem('sucursal_ingreso'))["codigo_sucursal"],
            estado:"A"
        } };
        this.generalFormatPmv = { tipo_servicio: 'most-productos', operacion: 'productosporcliente', operacion_tipo: 'consulta' };
        this.service.send({
            endpoint: this.constant.formConfiguration(),
            method: 'GET',
            body: this.datosConsultar,
            success: this.successItemsDevolver,
            error: this.error_,
            general: this.generalFormatPmv
        });
    }

    successItemsDevolver(data) {
        if(data.estado_p === 200){
            this.arrayIdsEliminar = this.case === 2 ? data.ultimo : data.data;
            this.arrayIdsEliminar.map(item => {
                this.eliminarItemsPedido(item.id);
                return null;
            });
        }else{
            this.getField('confirmModalCustom').toggle(false);
        }
    }

    eliminarItemsPedido(id) {
        this.datosConsultar = { datos: {
            id: id, 
            estado: "A"
        } };
        this.generalFormatPmv = { tipo_servicio: 'most-productos', operacion: 'eliminar', operacion_tipo: 'eliminar' };
        this.service.send({
            endpoint: this.constant.formConfiguration(),
            method: 'DELETE',
            body: this.datosConsultar,
            success: this.successEliminarItems,
            error: this.error_,
            general: this.generalFormatPmv
        });
    }

    successEliminarItems(data) {
        this.contDevolver += 1;
        this.case = null;

        if (this.contDevolver === this.arrayIdsEliminar.length) {
            let configCell = new Map();
            this.arrayNuevo = [];
            this.gridOptions['rowData'] = [];
            this.getField('tablaClientes').initData(this.gridOptions, configCell);
            this.getField('tablaClientes').toggle(false);
            this.registroSeleccionado = '';
            this.nuevoPedidosPendientes();
            this.getField('confirmModalCustom').toggle(false);
        }
    }


    traerCodigoFacturacion(props) {
        this.registroSeleccionado = props.data.documento;
        this.codigoConsecutivo = props.data.numero_pedido;
        this.id_cliente1 = props.data.id;
        this.sede = props.data.sede;
        this.nit = props.data.documento;

        this.datosConsultar = { datos: {
        } };
        this.generalFormatPmv = { tipo_servicio: 'most-pedidos', operacion: 'codigofacturacion', operacion_tipo: 'consulta' };
        this.service.send({
            endpoint: this.constant.formConfiguration(),
            method: 'GET',
            body: this.datosConsultar,
            success: this.successTraerFacturacion,
            error: this.error_,
            general: this.generalFormatPmv
        });
    }

    successTraerFacturacion(data) {
        if (data.estado_p === 200) {
            let codigoFacturacion = data.data[0].codigo_facturacion;
            this.getField('codigo_facturacion').setValue(data.data[0].codigo_facturacion);
            this.validarNumeroFactura(codigoFacturacion);

        } else if (data.estado_p === 404) {
            this.alertGeneral.toggle(true, 'El usuario no posee código de facturación asociado', "error");
        } else {
            this.alertGeneral.toggle(true, data.data.mensaje, "error");
        }
    }

    validarNumeroFactura(codigoFacturacion) {
        this.datosConsultar = { datos: {
            codigo_facturacion: codigoFacturacion
        } };
        this.generalFormatPmv = { tipo_servicio: 'most-facturacion', operacion: 'resolucion', operacion_tipo: 'consulta' };
        this.service.send({
            endpoint: this.constant.formConfiguration(),
            method: 'GET',
            body: this.datosConsultar,
            success: this.successValidaNumFactura,
            error: this.error_,
            general: this.generalFormatPmv
        });
    }

    successValidaNumFactura(data) {
        if (data.estado_p === 200) {
            let numFact = data.data[0].numero_factura + 1;
            let nfinal = data.data[0].nfinal;
            let fechaFinal = data.data[0].ffinal;
            let fechaVal = `${fechaFinal}, 23:59:59`;// '2022-06-29, ' + '23:59:59';
            let fechaActual = new Date();

            let month = '';
            if ((fechaActual.getMonth() + 1) < 10) {
                month = '0' + (fechaActual.getMonth() + 1);
            } else {
                month = fechaActual.getMonth() + 1;
            }

            let formatted_date = fechaActual.getFullYear() + '-' + month + '-' + fechaActual.getDate();

            if (numFact <= nfinal) {
                this.validNumFact = true;
            } else {
                this.validNumFact = false;
            }

            if (this.validarFechaMenorActual(fechaVal)) {
                this.validFechaFac = true;
            } else {
                this.validFechaFac = false;
            }

            if (!this.validNumFact) {

                this.getField('confirmModalCustom').setTitleAndContent('Mensaje', `Número de factura no disponible, última factura = ${numFact}, último número de la resolución es ${nfinal}`);
                this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
                this.getField('confirmModalCustom').setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
                this.getField("confirmModalCustom").setButtonConfirm(`Ok`); 
                this.getField('confirmModalCustom').toggle(true);

            } else if (!this.validFechaFac) {

                this.getField('confirmModalCustom').setTitleAndContent('Mensaje', `Fecha de reslución no permitida, fecha actual es ${formatted_date}, y fecha de resolución es ${fechaFinal}`);
                this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
                this.getField('confirmModalCustom').setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
                this.getField("confirmModalCustom").setButtonConfirm(`Ok`); 
                this.getField('confirmModalCustom').toggle(true);
            } else {
                this.datosClienteModificar(this.id_cliente1);
            }

        }
    }

    datosClienteModificar(id) {
        this.id_cliente = id;
        this.datosConsultar = { datos: {
            id: id,
            numero_pedido: this.codigoConsecutivo,
            estado:"A",
            codigo_sucursal: this.getField('sucursal_ingreso').getValue()
        } };
        this.generalFormatPmv = { tipo_servicio: 'most-clientes', operacion: 'buscarid', operacion_tipo: 'consulta' };
        this.service.send({
            endpoint: this.constant.formConfiguration(),
            method: 'GET',
            body: this.datosConsultar,
            success: this.successInfoCliente,
            error: this.error_,
            general: this.generalFormatPmv
        });
    }

    successInfoCliente(data) {
        if (data.estado_p === 200) {
            this.deptoIdCliente = data.data[0].departamento_geografico_id;
            this.ciudadIdCliente = data.data[0].ciudad_id;
            this.tipoDocumentoId = data.data[0].tipo_documento_id;
            this.agenteRetenedorCodigo = data.data[0].codigo_calidad_agente_retenedor_ventas;
            this.totalFactura = data.data[0].total;
            this.inicio = true;
            this.initInfoCliente(data.data[0]);
            this.limpiarMsjDeErrorInfoCliente();
        } else {
            this.tipoDocumentoId = 0;
        }
    }

    initInfoCliente(datos) {
        this.getField('requiere_factura_electronica').setValue('S');
        this.datos = datos;
        
        this.getField('calidad_agenteretenedor_id').setValue(datos.calidad_agente_retenedor_ventas_id);
        this.getField('nombre_cliente').setValue(datos.nombre_cliente);
        this.getField('nombre1').setValue(datos.nombre1);
        this.getField('nombre2').setValue(datos.nombre2);
        this.getField('apellido1').setValue(datos.apellido1);
        this.getField('apellido2').setValue(datos.apellido2);
        this.getField('btnContinuar').setVisible(true);
        this.getField('digito_verificacion').setValue(datos.digito_verificacion);
        if (datos.email_factura_electronica !== '' && datos.email_factura_electronica !== '.' && datos.email_secundario_facturacion_electronica !== '' &&
            datos.email_secundario_facturacion_electronica !== '.' && datos.email_secundario_facturacion_electronica !== null) {
            this.getField('email_secundario').setValue(datos.email_secundario_facturacion_electronica);
            this.getField('email_principal').setValue(datos.email_factura_electronica);
            this.limpiarMsjDeErrorInfoCliente();
        } else if ((datos.email_factura_electronica !== '' && datos.email_factura_electronica !== '.') && (datos.email_secundario_facturacion_electronica === '' ||
            datos.email_secundario_facturacion_electronica === '.' || datos.email_secundario_facturacion_electronica === null)) {
            this.getField('email_secundario').setValue(datos.email_factura_electronica);
            this.getField('email_principal').setValue(datos.email_factura_electronica);
            this.limpiarMsjDeErrorInfoCliente();
        } else if ((datos.email_factura_electronica === '.' && datos.email_secundario_facturacion_electronica === '.') ||
            (datos.email_factura_electronica === '' && datos.email_secundario_facturacion_electronica === '.') ||
            (datos.email_factura_electronica === '.' && datos.email_secundario_facturacion_electronica === '') ||
            (datos.email_factura_electronica === '' && datos.email_secundario_facturacion_electronica === '') ||
            (datos.email_factura_electronica === null && datos.email_secundario_facturacion_electronica === null) ||
            (datos.email_factura_electronica === '' && datos.email_secundario_facturacion_electronica === null) ||
            (datos.email_factura_electronica === null && datos.email_secundario_facturacion_electronica === '') ||
            (datos.email_factura_electronica === '.' && datos.email_secundario_facturacion_electronica === null) ||
            (datos.email_factura_electronica === null && datos.email_secundario_facturacion_electronica === '.')) {
            this.getField('email_secundario').setValue(datos.email_defecto_envio);
            this.getField('email_principal').setValue(datos.email_defecto_envio);
            this.limpiarMsjDeErrorInfoCliente();
        } else if ((datos.email_factura_electronica === '' || datos.email_factura_electronica === '.') && (datos.email_secundario_facturacion_electronica !== '' &&
            datos.email_secundario_facturacion_electronica !== '.' && datos.email_secundario_facturacion_electronica !== null)) {
            this.getField('email_secundario').setValue(datos.email_secundario_facturacion_electronica);
            this.getField('email_principal').setValue(datos.email_secundario_facturacion_electronica);
            this.limpiarMsjDeErrorInfoCliente();
        }

        this.getField('iva').setValue(datos.total_iva);
        this.recuperarTipoDoc();
        this.recuperarAgenteRet();

        this.getField('btn_guardar_info').setClick(this.guardarInfoCliente);
        this.getField('btn_facturar').setClick(() => this.facturarProducto(datos.id));
        for (let data in datos) {
            if (this.getField(data) && data !== 'total_items' && data !== 'tipo_persona')
                this.getField(data).setValue(datos[data]);
            else if (data === 'total_items')
                this.getField('total_items2').setValue(datos[data]);
            else if (data === 'tipo_persona')
                if (datos[data] === 'N')
                    this.getField('tipo_persona').setValue('N');
                else if (datos[data] === '.')
                    this.getField('tipo_persona').setValue(' ');
                else
                    this.getField('tipo_persona').setValue('J');
        }
        this.paisIdcliente = this.getField("pais_id").getValue();
        this.getField('valor_total_remision').setValue(datos.total_general_remision);
        this.getField('valor_total_factura').setValue(datos.total_general_factura);
        if(this.maneja_remision){
            this.getField('observaciones').setValue(datos.observacion);
        }
        this.getField('modal_datos_cliente').handleClickOpen();
    }

    recuperarTipoDoc() {
        this.datosConsultar = { datos: {
        } };
        this.generalFormatPmv = { tipo_servicio: 'most-tiposdocumentos', operacion: 'todosgenerales', operacion_tipo: 'consulta' };
        this.service.send({
            endpoint: this.constant.formConfiguration(),
            method: 'GET',
            body: this.datosConsultar,
            success: this.successRecuperarTipoDoc,
            error: this.error_,
            general: this.generalFormatPmv
        });
    }

    successRecuperarTipoDoc(data) {
        if (data.data.length > 0) {
            let opciones = [{ text: 'Ninguno', value: ' ', 'campos_cambian': {} }];
            data.data.forEach(item => {
                if (this.tipoDocumentoId === item.id) {
                    this.getField('nombre_tipo_doc').setValue(item.nombre);
                }
                let dataOp = {}
                dataOp['value'] = item.id;
                dataOp['text'] = item.nombre;
                dataOp['campos_cambian'] = {nombre_tipo_doc: item.nombre};
                opciones.push(dataOp);
            })
            this.getField('tipo_documento_id').setOptions(opciones);
                this.getField('tipo_documento_id').setValue(this.tipoDocumentoId);
            if (this.getField('tipo_documento_id').getValue() === ' ' || this.getField('tipo_documento_id').getValue() === '') {
                this.getField('tipo_documento_id').setValue(this.tipoDocumentoId);
            }

            if(this.getField('nombre_tipo_doc').getValue() === 'NIT'){
                this.getField('nombre_cliente').setDisabled(false)
                this.getField('nombre1').setDisabled(true)
                this.getField('nombre2').setDisabled(true)
                this.getField('apellido1').setDisabled(true)
                this.getField('apellido2').setDisabled(true)

            }else{
                this.getField('nombre_cliente').setDisabled(true)
                this.getField('nombre1').setDisabled(false)
                this.getField('nombre2').setDisabled(false)
                this.getField('apellido1').setDisabled(false)
                this.getField('apellido2').setDisabled(false)
            }
            this.getField('tipo_documento_id').setError(false,'');
        }
    }

    recuperarAgenteRet() {
        this.datosConsultar = { datos: {
        } };
        this.generalFormatPmv = { tipo_servicio: 'most-agenteretenedorventas', operacion: 'todosterceros', operacion_tipo: 'consulta' };
        this.service.send({
            endpoint: this.constant.formConfiguration(),
            method: 'GET',
            body: this.datosConsultar,
            success: this.successRecuperarAgenteRet,
            error: this.error_,
            general: this.generalFormatPmv
        });
    }

    successRecuperarAgenteRet(data) {
        if (data.data.length > 0) {
            let opciones = [{ text: 'Ninguno', value: ' ', 'campos_cambian': {} }];
            data.data.forEach(item => {
                let dataOp = {}
                dataOp['value'] = item.codigo;
                dataOp['text'] = item.codigo + " - " + item.descripcion;
                dataOp['campos_cambian'] = { calidad_agenteretenedor_id: item.id };
                opciones.push(dataOp);
            })
            this.getField('codigo_agente_retenedor').setOptions(opciones);
            if (this.agenteRetenedorCodigo !== '' && this.agenteRetenedorCodigo !== ' ') {
                this.getField('codigo_agente_retenedor').setValue(this.agenteRetenedorCodigo);
            }
            if (this.getField('codigo_agente_retenedor').getValue() === ' ' || this.getField('codigo_agente_retenedor').getValue() === '') {
                this.getField('codigo_agente_retenedor').setValue('R-99-PN');
                this.getField('calidad_agenteretenedor_id').setValue(1);
            }
            this.agenteRetenedorCodigo = '';
        }
    }



    consultarCodigoFacturacion() {

        let estadoCamposModalFacturar = false; // Esta variable va a servir para identidicar si los campos de la modal Facturar estan correctamente seteados para consumir el servicio "289_retorna_codigo_fact_usuario" o no.
        let estadoCampoTransferencia = false;
        let estadoCampoConsignacion = false
        let observaciones = this.getField('observaciones').getValue();
        if (observaciones !== '' && observaciones !== ' ' && observaciones !== undefined) { //this.getField('observaciones').valid()
            estadoCamposModalFacturar = true;
        } else {
            this.getField('observaciones').setError(true,'* Este Campo es requerido');//this.getField('observaciones').valid();
            this.getField('btnAceptar').setDisabled(false);
            estadoCamposModalFacturar = false;
        }

        if(this.getField('valor_transferencia').getValue() > 0 && this.getField('valor_transferencia').getValue() !== '' ){
            if(this.getField('banco_transferencia').getValue() !== ''){
                estadoCampoTransferencia = true;
                this.getField('banco_transferencia').setError(false,'');
            }else{
                estadoCampoTransferencia = false;
                this.getField('banco_transferencia').setError(true,'* Este campo es requerido');
            }
        }else {
            if(this.getField('banco_transferencia').getValue() === '' || this.getField('banco_transferencia').getValue() === ' '){
                estadoCampoTransferencia = true;
            }
        }

        if(this.getField('valor_consignacion').getValue() > 0 && this.getField('valor_consignacion').getValue() !== '' ){
            if(this.getField('banco_consignacion').getValue() !== ''){
                estadoCampoConsignacion = true;
                this.getField('banco_consignacion').setError(false,'');
            }else{
                estadoCampoConsignacion = false;
                this.getField('banco_consignacion').setError(true,'* Este campo es requerido');
            }
        }else {
            if(this.getField('banco_consignacion').getValue() === '' || this.getField('banco_consignacion').getValue() === ' '){
                estadoCampoConsignacion = true;
            }
        }

        if (this.getField('tarjeta_credito').getValue() > 0 && estadoCamposModalFacturar === true) {
            let codigo_tarjeta_credito = this.getField('codigo_tarjeta_credito').getValue();
            let aprobacion_tarjeta_credito = this.getField('aprobacion_tarjeta_credito').getValue();

            if(!(codigo_tarjeta_credito !== '' && codigo_tarjeta_credito !== ' ' && codigo_tarjeta_credito !== undefined &&
            aprobacion_tarjeta_credito !== '' && aprobacion_tarjeta_credito !== ' ' && aprobacion_tarjeta_credito !== undefined)){
                estadoCamposModalFacturar = false;
                if (!(this.getField('codigo_tarjeta_credito').valid())) {
                    this.getField('codigo_tarjeta_credito').setError(true,'* Este Campo es requerido');
                }
                if (!(this.getField('aprobacion_tarjeta_credito').valid())) {
                    this.getField('aprobacion_tarjeta_credito').setError(true,'* Este Campo es requerido');
                }
            }
        }

        if (this.getField('tarjeta_debito').getValue() > 0 && estadoCamposModalFacturar === true) {
            let codigo_tarjeta_debito = this.getField('codigo_tarjeta_debito').getValue();
            let aprobacion_tarjeta_debito = this.getField('aprobacion_tarjeta_debito').getValue();

            if(!(codigo_tarjeta_debito !== '' && codigo_tarjeta_debito !== ' ' && codigo_tarjeta_debito !== undefined &&
            aprobacion_tarjeta_debito !== '' && aprobacion_tarjeta_debito !== ' ' && aprobacion_tarjeta_debito !== undefined)){
                estadoCamposModalFacturar = false;
                if (!(this.getField('codigo_tarjeta_debito').valid())) {
                    this.getField('codigo_tarjeta_debito').setError(true,'* Este Campo es requerido');
                }
                if (!(this.getField('aprobacion_tarjeta_debito').valid())) {
                    this.getField('aprobacion_tarjeta_debito').setError(true,'* Este Campo es requerido');
                }
            }
        }
        
        
        if (estadoCamposModalFacturar && estadoCampoTransferencia && estadoCampoConsignacion) {
            this.getField('btnAceptar').setDisabled(true); ///            
            if(this.getField('total_factura').getValue() === 0){

                this.datosConsultar = { datos: {
                } };
                this.generalFormatPmv = { tipo_servicio: 'most-pedidos', operacion: 'codigofacturacion', operacion_tipo: 'consulta' };
                this.service.send({
                    endpoint: this.constant.formConfiguration(),
                    method: 'GET',
                    body: this.datosConsultar,
                    success: this.successGuardarFact,
                    error: this.error_,
                    general: this.generalFormatPmv
                });
            } else {
                this.alertGeneral.toggle(true,'Debe pagar el total de la factura','error');
                this.getField('btnAceptar').setDisabled(false); ///
            }
        }else{
            this.alertGeneral.toggle(true,'Error en alguna configuración','error');
            this.getField('btnAceptar').setDisabled(false); 
        }
    }

    successGuardarFact(data) {
        if (data.estado_p === 200) {
            if (this.getField('codigo_facturacion').getValue() !== ' ' &&
                this.getField('codigo_facturacion').getValue() !== '' &&
                this.getField('codigo_facturacion').getValue() !== undefined) {
                let seguirFactura = true;
                if (this.validCredito)
                    if (this.getField('aprobacion_tarjeta_credito').valid() && this.getField('codigo_tarjeta_credito').valid() && this.getField('tarjeta_credito').valid() && this.getField('tarjeta_credito').getValue() > 0)
                        seguirFactura = true;
                    else
                        seguirFactura = false;
                if (this.validDebito)
                    if (this.getField('aprobacion_tarjeta_debito').valid() && this.getField('codigo_tarjeta_debito').valid() && this.getField('tarjeta_debito').valid() && this.getField('tarjeta_debito').getValue() > 0)
                        seguirFactura = true;
                    else
                        seguirFactura = false;


                //console.log('seguir Factura:',seguirFactura);
                if (this.getField('efectivo').valid() && this.getField('cheques').valid() && this.getField('otros').valid() && seguirFactura  ) { //nueva validacion para facurar this.getField('efectivo').valid() && this.getField('efectivo').getValue > 0 && this.getField('cheques').valid() && this.getField('cheques').getValue() > 0 && this.getField('otros').valid() && this.getField('otros').getValue() > 0 && seguirFactura --
            
                    let efectivo = (!isNaN(parseFloat(this.getField('efectivo').getValue()))) ? parseFloat(this.getField('efectivo').getValue()) : 0; //(this.getField('efectivo').getValue() > 0 || this.getField('cheques').getValue() > 0 || this.getField('otros').getValue() > 0 || this.getField('tarjeta_debito').getValue() > 0 || this.getField('tarjeta_credito').getValue() > 0) && seguirFactura
                    let anticipo_caja = (!isNaN(parseFloat(this.getField('anticipo_caja').getValue()))) ? parseFloat(this.getField('anticipo_caja').getValue()) : 0; //(this.getField('efectivo').getValue() > 0 || this.getField('cheques').getValue() > 0 || this.getField('otros').getValue() > 0 || this.getField('tarjeta_debito').getValue() > 0 || this.getField('tarjeta_credito').getValue() > 0) && seguirFactura
                    let cheque = (!isNaN(parseFloat(this.getField('cheques').getValue()))) ? parseFloat(this.getField('cheques').getValue()) : 0;
                    let tarjeta_nro1 = (!isNaN(parseFloat(this.getField('tarjeta_debito').getValue()))) ? parseFloat(this.getField('tarjeta_debito').getValue()) : 0;
                    let tarjeta_nro2 = (!isNaN(parseFloat(this.getField('tarjeta_credito').getValue()))) ? parseFloat(this.getField('tarjeta_credito').getValue()) : 0;
                    let otros = (!isNaN(parseFloat(this.getField('otros').getValue()))) ? parseFloat(this.getField('otros').getValue()) : 0;
                    let valor_transferencia = (!isNaN(parseFloat(this.getField('valor_transferencia').getValue()))) ? parseFloat(this.getField('valor_transferencia').getValue()) : 0;
                    let valor_consignacion = (!isNaN(parseFloat(this.getField('valor_consignacion').getValue()))) ? parseFloat(this.getField('valor_consignacion').getValue()) : 0;
                    let cambio = (efectivo + anticipo_caja + cheque + otros + tarjeta_nro1 + tarjeta_nro2 + valor_consignacion + valor_transferencia) - this.totalFactura;
                    
                    if (cambio < 0)
                        cambio = 0;
                        
                        let body = {
                            nitsx_sedes_id: this.id_cliente,
                            efectivo: (this.getField('efectivo').getValue() === '') ? 0 : this.getField('efectivo').getValue(),
                            anticipo_caja: (this.getField('anticipo_caja').getValue() === '') ? 0 : this.getField('anticipo_caja').getValue(),
                            cheques: (this.getField('cheques').getValue() === '') ? 0 : this.getField('cheques').getValue(),
                            tarjeta_debito: (this.getField('tarjeta_debito').getValue() === '') ? 0 : this.getField('tarjeta_debito').getValue(),
                            tarjeta_credito: (this.getField('tarjeta_credito').getValue() === '') ? 0 : this.getField('tarjeta_credito').getValue(),
                            otros: (this.getField('otros').getValue() === '') ? 0 : this.getField('otros').getValue(),
                            devuelta: cambio,
                            codigo_tarjeta_credito: this.getField('codigo_tarjeta_credito').getValue(),
                            codigo_tarjeta_debito: this.getField('codigo_tarjeta_debito').getValue(),
                            aprobacion_tarjeta_credito: this.getField('aprobacion_tarjeta_credito').getValue(),
                            aprobacion_tarjeta_debito: this.getField('aprobacion_tarjeta_debito').getValue(),
                            cliente_requiere_factura_electronica: this.getField('cliente_requiere_factura_electronica').getValue(),
                            codigo_facturacion: this.getField('codigo_facturacion').getValue(),
                            observaciones: this.getField('observaciones').getValue(),
                            valor_consignacion:this.getField('valor_consignacion').getValue(),
                            valor_transferencia:this.getField('valor_transferencia').getValue(),
                            banco_transferencia: this.getField('banco_transferencia').getValue(),
                            banco_consignacion: this.getField('banco_consignacion').getValue(),
                            hora_transferencia: this.hora_transferencia !== '' & this.hora_transferencia !== undefined  && this.hora_transferencia !== 'undefined'  ? this.hora_transferencia : new Date().toString().split(' ')[4],//this.getField('hora_transferencia').getValue(),
                            hora_consignacion: this.hora_consignacion !== '' && this.hora_consignacion !== undefined  && this.hora_consignacion !== 'undefined' ? this.hora_consignacion : new Date().toString().split(' ')[4],//this.getField('hora_consignacion').getValue(),
                            numero_pedido: this.codigoConsecutivo,
                            codigo_sucursal:this.getField('sucursal_ingreso').getValue()
                        };       // body : es un objeto que contiene los parámetros que se necesitan para realizar la peticion
                        this.getField('btnAceptar').setDisabled(true);///
                        if(this.getField('valor_transferencia').getValue()<=0){
                            body.hora_transferencia='00:00:00';
                        }
                        if(this.getField('valor_consignacion').getValue()<=0){
                            body.hora_consignacion='00:00:00';
                        }

                        this.datosConsultar = { datos: body };
                        this.generalFormatPmv = { tipo_servicio: 'most-facturacion', operacion: 'generarfactura', operacion_tipo: 'crear' };
                        this.service.send({
                            endpoint: this.constant.formConfiguration(),
                            method: 'POST',
                            body: this.datosConsultar,
                            success: this.successPagoFactura,
                            error: this.error_,
                            general: this.generalFormatPmv,
                            showMessage: false
                        });
                        
                }
            } else {
                this.getField('btnAceptar').setDisabled(false);
            }
        } else if (data.estado_p === 404) {
            this.alertGeneral.toggle(true, 'El usuario no posee código de facturación asociado', "error");
            this.getField('btnAceptar').setDisabled(false);
        } else {
            this.alertGeneral.toggle(true, data.data.mensaje, "error");
            this.getField('btnAceptar').setDisabled(false);
        }
    }

    successPagoFactura(data) {
        this.getField('btnAceptar').setDisabled(false);
        if (data.estado_p === 200) {

            this.factura = data.data.factura;
            this.remision = data.data.remision;
            this.getField('confirmModalCustom').setTitleAndContent('Factura creada', data.data.mensaje);
            this.getField("confirmModalCustom").setVisibleCancel(true);
            this.getField('confirmModalCustom').setClickDialog(this.aceptarFactura);
            this.getField("confirmModalCustom").setButtonConfirm(`Aceptar`); 
            this.getField('confirmModalCustom').toggle(true);//
        } else if (data.estado_p === 404) {
            this.getField('fieldAlert').toggle(true, data.data.mensaje, "error");
        } else {
            if (data.estado_p === 500) {
                this.getField('fieldAlert').toggle(true, data.data.mensaje, "error");

            } else if (data.estado_p === 502) {
                let keysError = '';
                keysError = Object.keys(data.data.errores);
                let key = '';
                key = keysError[0];
                let error = data.data.errores[key];

                this.getField('fieldAlert').toggle(true, `Error en la petición. ${key}: ${error}`, "error");
            }
        }
    }

    aceptarFactura() {
        this.getField('confirmModalCustom').toggle(false);
        //
        if(this.getField("anticipo_caja").getValue() > 0)
            this.actualizarCartera();
        this.getField('modalFacturar').handleClose();
        this.limpiarSmsErrorCamposModalFacturar();
        this.getField('modal_datos_cliente').handleClose();
        this.limpiarMsjDeErrorInfoCliente();
        //this.arrayNuevo = [];
        this.registroSeleccionado = '';
        //this.nuevoPedidosPendientes();
        //this.buscarClientes();

    }

    actualizarCartera(){
        this.mostrarMensajeGenerando(); 

        this.datosConsultar = { datos: {
            numero_pedido:parseInt(this.numero_pedido),
            fecha:this.fecha_anticipo,
            factura:this.factura === " "? ".":this.factura,
            remision:this.remision === " "? ".":this.remision,
            saldo_anticipo: this.saldo_anticipo1 === null ? 0.0:parseFloat(this.saldo_anticipo1),
            anticipo:this.getField('anticipo_caja').getValue()
        } };
        this.generalFormatPmv = { tipo_servicio: 'most-clientes', operacion: 'modificapedidocartera', operacion_tipo: 'modificar' };
        this.service.send({
            endpoint: this.constant.formConfiguration(),
            method: 'PUT',
            body: this.datosConsultar,
            success: this.successActualizarCartera,
            error: this.error_,
            general: this.generalFormatPmv,
            showMessage: false
        });
    }

    successActualizarCartera(data){
        if (data.estado_p === 200) { // si estado_p === 200, se debe mostrar el confirm dialog para agregar el producto
            this.getField('confirmModalCustom').toggle(false);
            this.alertGeneral.toggle(true,"Datos Actualizados.","success");
        } else { // sino se debe mostrar el dialog de descuento no permitido
            this.getField('confirmModalCustom').toggle(false);
            this.alertGeneral.toggle(true,data.data.mensaje,"error");
        } 
    }

    campoCodigoTarjetaCredito(){
        this.getField('tarjeta_credito').setError(false, '');
        this.getField('codigo_tarjeta_credito').setError(false, '');
        this.getField('aprobacion_tarjeta_credito').setError(false, '');

        if(this.getField('codigo_tarjeta_credito').valid()){
            this.getField('aprobacion_tarjeta_credito').setDisabled(false);
        }else{
            this.getField('aprobacion_tarjeta_credito').setDisabled(true);
            this.getField('aprobacion_tarjeta_credito').setValue('');
            this.getField('aprobacion_tarjeta_credito').setError(false,'');
            this.getField('codigo_tarjeta_credito').setDisabled(true);
            this.getField('codigo_tarjeta_credito').setError(false,'');
            this.getField('tarjeta_credito').setValue(0);
        }
    }

    campoCodigoTarjetaDebito(){
        this.getField('tarjeta_debito').setError(false, '');
        this.getField('codigo_tarjeta_debito').setError(false, '');
        this.getField('aprobacion_tarjeta_debito').setError(false, '');

        if(this.getField('codigo_tarjeta_debito').valid()){
            this.getField('aprobacion_tarjeta_debito').setDisabled(false);
        }else{
            this.getField('aprobacion_tarjeta_debito').setDisabled(true);
            this.getField('aprobacion_tarjeta_debito').setValue('');
            this.getField('aprobacion_tarjeta_debito').setError(false,'');
            this.getField('codigo_tarjeta_debito').setDisabled(true);
            this.getField('codigo_tarjeta_debito').setError(false,'');
            this.getField('tarjeta_debito').setValue(0);
        }
    }


    campoValorConsignacion(){
        let valor_consignacion = this.getField('valor_consignacion').getValue();
        //let valor_transferencia = this.getField('valor_transferencia').getValue();

        if(valor_consignacion  <=  0){
            this.validConsignacion = false;
            this.getField('banco_consignacion').setDisabled(true);
            this.getField('banco_consignacion').setValue(' ');
            this.getField('banco_consignacion').setError(false,'');
            this.getField('valor_consignacion').setError(false,'');
        } else {
            this.validConsignacion = true;
        }

        //console.log('entro setKeyUp consignacion');
        
        //this.getField('otros').setValue(Number(valor_consignacion)+Number(valor_transferencia));

    }

    campoValorTransferencia(){
        let valor_transferencia = this.getField('valor_transferencia').getValue();
        //let valor_consignacion =  this.getField('valor_consignacion').getValue();

        if(valor_transferencia <= 0){
            if(this.getField('banco_transferencia').valid()){

                this.validTranferencia = false;
            }
            this.validTranferencia = true;

            this.getField('banco_transferencia').setDisabled(true);
            this.getField('banco_transferencia').setValue(' ');
            this.getField('banco_transferencia').setError(false,'');
            this.getField('valor_transferencia').setError(false,'');
        } else { 
            if(this.getField('banco_transferencia').valid()){
                this.validTranferencia = true;
            }else{
                this.validTranferencia = false;
            }
            //this.validTranferencia = true;
        }

        //console.log('entro setKeyUp transferencia');
        
        //this.getField('otros').setValue(Number(valor_consignacion)+Number(valor_transferencia));
    }


    limpiarCamposOtrasFormasPago(campo){
        let valor = '';
        //let otros_suma = 0;

        if(campo === 'pt'){
            this.getField('banco_transferencia').setError(false,''); 
            valor = this.getField('banco_transferencia').getValue();
        }

        if(campo === 'pc'){
            this.getField('banco_consignacion').setError(false,'');
            valor = this.getField('banco_consignacion').getValue();
        }

        if(valor === '' || valor === ' ' || valor === null || valor === undefined){

            if(campo === 'pt'){
                this.getField('valor_transferencia').setValue(0);
                this.getField('banco_transferencia').setDisabled(true);
            }
            if(campo === 'pc'){
                this.getField('valor_consignacion').setValue(0);
                this.getField('banco_consignacion').setDisabled(true);

            }
            //JBAS-2964 se pidio no sumar en otros, y dejar otros como independiente
            //otros_suma = this.getField('valor_transferencia').getValue() + this.getField('valor_consignacion').getValue();
            //this.getField('otros').setValue(otros_suma);

            this.formatearCambio();

        }
    }

    limpiarMsjDeErrorInfoCliente() {
        this.getField('email_secundario').setError(false, '');
        this.getField('requiere_factura_electronica').setError(false, '');
        this.getField('nombre1').setError(false, '');
        this.getField('nombre2').setError(false, '');
        this.getField('apellido1').setError(false, '');
        this.getField('apellido2').setError(false, '');
        this.getField('direccion').setError(false, '');
        this.getField('telefonos').setError(false, '');
        this.getField('efectivo').setError(false, '');
        this.getField('anticipo_caja').setError(false, '');
        this.getField('cheques').setError(false, '');
        this.getField('otros').setError(false, '');
        this.getField('observaciones').setError(false, '');
    }

    mostrarMensajeNoHayDatos(){
        this.getField('confirmModalCustom').setTitleAndContent('Mensaje', 'La consulta no arrojo datos.');
        this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);       
    }

    validarFechaMenorActual(date) {
        var x = new Date(date);
        var today = new Date();
        if (x <= today)
            return false;
        else
            return true;
    }
}
FormJaivana.addController('most-caja', CustomCajaMostrador);
export default CustomCajaMostrador;